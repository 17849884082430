<template>
  <div class="create-box" v-if="!showInput" @click="showInputForm">
    <div class="create-head">+ Create Folder</div>
    <div class="folder-illustrates">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div v-else class="input-box">
    <form action="" @submit="handleCreateFolder">
      <input
        type="text"
        placeholder="Folder Name"
        @focusout="handleInputFocusOut"
        ref="folderRef"
        v-model="folderName"
      />
      <button>Create</button>
    </form>
  </div>
</template>

<script setup>
import { createFolder } from "@/api/wishlist-folder";
import { nextTick, ref, defineEmits } from "vue";

const showInput = ref(false);
const folderRef = ref();
const folderName = ref("");

const emit = defineEmits(["refreshFolderData"]);

const handleInputFocusOut = () => {
  setTimeout(() => {
    showInput.value = false;
  }, 200);
};

const showInputForm = () => {
  showInput.value = true;
  nextTick(() => {
    folderRef.value?.focus();
  });
};

const handleCreateFolder = async (e) => {
  e.preventDefault();

  if (folderName.value) {
    const response = await createFolder(folderName.value);
    if (response) {
      folderName.value = "";
      showInput.value = false;
      emit("refreshFolderData");
    }
  }
};
</script>

<style scoped>
.create-box {
  padding-inline: 15px;
}
.create-head {
  margin-top: 20px;
  margin-bottom: 0;
  color: white;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  opacity: 0.8;
}

.folder-illustrates {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.folder-illustrates div {
  flex: 1;
  background: #1e2d44;
  flex-basis: calc((100% - 10px) / 2);
  aspect-ratio: 3/1.2;
  border-radius: 5px;
}

.input-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.input-box input {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  outline: none;
  color: rgba(255, 255, 255, 0.8);
  padding: 8px 0;
  width: 100%;
  margin-bottom: 15px;
}

.input-box input::placeholder {
  color: gray;
}

.input-box button {
  background: #fffc84;
  color: rgb(0, 0, 0);
  width: 100%;
  font-weight: bold;

  border: none;
  outline: none;
  padding: 7px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 1px;
}
</style>
