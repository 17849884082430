<template>
  <div class="policyBody">
    <div class="container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <div
      style="
        display: flex;
        justify-content: flex-end;
        color: white;
        padding-right: 20px;
        margin: 10px;
      "
      v-show="store.isMobile"
      @click="exitPage()"
    >
      <img src="../assets/img/close.png" />
    </div>
    <div style="display: flex">
      <div class="column left"></div>
      <section id="policy-container">
        <div class="terms">
          <h3>PRIVACY POLICY</h3>
          <p>Last updated: November 9 31, 2023</p>

          <p>
            CINEFLARES, is operated by TRUELENS PRODUCTIONS, INC. dba
            &ldquo;CINEFLARES&rdquo; (&ldquo;CINEFLARES,&rdquo;
            &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;). This
            Privacy Policy describes how CINEFLARES handles personal information
            we collect about users of our website www.cineflares.com (the
            &ldquo;Service&rdquo;). You acknowledge that this Privacy Policy is
            part of our Terms of Use, and by accessing or using our site, you
            agree to be bound by all of its terms and conditions. If you do not
            agree to these terms, please do not access or use this site.
          </p>

          <h3>Personal Information We Collect</h3>
          <p>
            We collect personal information you give us, such as: Account
            information. We collect personal information when you create an
            account, such as your first name, last name, email address and
            password. Feedback and correspondence. You may share information
            with us when you respond to surveys, participate in promotions or
            events, request customer support or otherwise interact with us.
          </p>
          <p>Sensitive personal information</p>
          <p>
            We will never ask you to send us or submit to the Service any
            information related to racial or ethnic origin; political opinions
            or union membership; religious or other beliefs; your health,
            biometric or genetic information; information about your sexual
            preference or sexual orientation; or information about your criminal
            background.
          </p>

          <p>Information we collect automatically</p>

          <p>
            We use server logs, cookies and similar technologies to
            automatically collect information about your computer or device and
            how you interact with our Service. For example, we may record your
            application version, browser type, geographic location, computer or
            mobile operating system, Internet Protocol (IP) address (a number
            that is automatically assigned to your computer or mobile device
            when you use the Internet, which may vary from session to session)
            and your unique device, application or advertising identifiers. In
            addition to information about your device, we collect information
            about how you interact with the Service, such as the page that
            referred you, your search terms, pages or areas of the Service you
            use or share, the time and duration of visits, features you used,
            links you clicked on and the names and number of users you interact
            with. See our Cookies and Similar Technologies Notice below for more
            information about how the Service uses cookies and similar
            technologies to collect your information when you use the Service.
          </p>

          <h3>How We Use Your Personal Information</h3>

          <p>
            To provide the Service - We use your personal information to
            provide, support, understand and improve the Service. As part of
            providing the Service we may send you service announcements,
            technical notices, updates, security alerts and support-related
            messages through the Service or by email. We may also communicate
            with you in response to your support requests, questions or
            feedback.
          </p>

          <p>
            To personalize the Service we use the information we collect to
            better understand your interests, make personalized recommendations
            based on your interests or information and otherwise personalize
            your experience with the Service.
          </p>
          <p>
            To send you marketing communications - If you use the Service,
            request information from us, or participate in our surveys,
            promotions or events, we may send you marketing communications. You
            can always opt out of these communications by following the opt-out
            instructions in the message or by contacting us.
          </p>

          <p>
            To create anonymous data we may create aggregate and other anonymous
            data from our users&rsquo; personal information. We make personal
            information into anonymous data by removing information that makes
            the data personally identifiable to you. We may use this anonymous
            data and share it with third parties to operate, understand and
            improve our Service, to develop new services, and for other lawful
            business purposes. For security, compliance, fraud prevention and
            safety We may use your personal information as we believe
            appropriate to investigate or prevent violation of the law or our
            Terms of Service; to secure the Service; to protect our, your or
            others&rsquo; rights, privacy, safety or property; and to protect,
            investigate and deter against fraudulent, harmful, unauthorized,
            unethical or illegal activity. We may also use information we
            collect about your device to detect users violating our Terms of
            Service and prevent further violations.
          </p>
          <p>
            For compliance with law; legal claims we may use your personal
            information as we believe appropriate to comply with applicable
            laws, lawful requests and legal process, such as to respond to
            subpoenas or requests from government authorities; where permitted
            by law in connection with any legal investigation; and to prosecute
            or defend legal claims.
          </p>

          <p>
            In some cases we may ask for your consent to collect, use or share
            your personal information with your consent.
          </p>

          <h3>How We Share Your Personal Information</h3>
          <p>
            Affiliates - We may share some or all of your personal information
            with our subsidiaries, joint ventures, or other companies under
            common control, in which case we will require those entities to
            honor this Privacy Policy.
          </p>

          <p>
            Service providers - We may employ third party companies or
            individuals to provide services that support the Service, such as
            customer support, hosting, website analytics and email delivery. We
            authorize these service providers to use your personal information
            only as necessary in connection with providing their services to us.
            For compliance with law, legal claims, security, compliance, fraud
            prevention and safety We may disclose information about you as we
            believe appropriate to government or law enforcement officials or
            private parties for the security, compliance, fraud prevention and
            safety purposes described above; as required by law, lawful requests
            or legal process; where permitted by law in connection with any
            legal investigation; and to prosecute or defend legal claims.
          </p>

          <p>
            Business transfers -We may sell, transfer or otherwise share some or
            all of our business or assets, including your personal information,
            in connection with a business deal or potential business deal such
            as a merger, consolidation, acquisition, reorganization, sale of
            assets or in the event of bankruptcy.
          </p>

          <h3>Your Choices</h3>
          <p>Access, correct or delete</p>
          <p>
            If you have an account, you can access and update the personal
            information in your profile. Registered users may also
            <a href="mailto:contact@cineflares.com">contact us</a> to request
            deletion of your account and personal information. We may request
            information from you to help us confirm your identity and process
            your request. Applicable law may require or permit us to decline
            your request, such as where we need to retain your information for
            security, compliance, fraud prevention and safety, to comply with
            law or for legal claims. If we decline your request, we will tell
            you why, subject to legal restrictions. Once we process your request
            you will not be able to access your account or use the Service. When
            we delete any information, it will be deleted from our active
            database, but where permitted by law, may remain for a period of
            time in our backup files or other instances where deletion is not
            practicable.
          </p>

          <p>
            Social Media Widgets - Our Service may include social media
            features, such as the Facebook, X and Instagram widgets. These
            features may collect your personal information and track your use of
            the Service. These social media features are either hosted by a
            third party or hosted directly in the Service. Your interactions
            with these features are governed by the privacy policy of the
            company providing such functionality.
          </p>

          <p>
            Payment Information - Any payment card information you use to make a
            purchase on the Service is collected and processed directly by our
            payment processor, Stripe, and we never receive or store your full
            payment card information. Stripe commits to complying with the
            Payment Card Industry Data Security Standard (PCI-DSS) and using
            industry standard security. Stripe may use your Payment Information
            in accordance with its own Privacy Policy here.
          </p>
          <p>
            Security - We use administrative, technical, and physical security
            measures to protect against unauthorized access to, or unauthorized
            use, alteration, disclosure, or destruction of, personal information
            covered by this Privacy Policy. However, no method of protection and
            no transmission over the internet is 100% secure and we cannot
            guarantee the security of your personal information. You hereby
            acknowledge that we are not responsible for any intercepted
            information sent via the Internet, and you hereby release us from
            any and all claims arising out of or related to the use of
            intercepted information in any unauthorized manner. International
            Data Use
          </p>

          <p>
            CINEFLARES is headquartered in the United States and has service
            providers and users in other countries. Your personal information
            may be collected, used and stored in the United States or in other
            locations outside of your home country. Privacy laws in the
            locations where we handle your personal information may not be as
            protective as the privacy laws in your home country.
          </p>

          <p>
            Third Party Sites and Services - The Service may link to or
            integrate with other websites and services operated by third
            parties, including social media platforms, advertising services and
            other websites and applications. These links and integrations are
            not endorsements of, or representations that we are affiliated with,
            any third party. We do not control third party websites,
            applications or services and we are not responsible for their
            actions. Third party websites and services follow different rules
            regarding their collection, use and sharing of your personal
            information and we encourage you to read their privacy policies to
            learn more.
          </p>
          <p>
            Children - We do not knowingly collect information from children. We
            may, however, ask the user to provide us with the user's age
            information. If the person indicates that the person is under 13
            years old, as permitted by law, we will collect no or limited
            personal information (e.g. persistent identifier and/or email
            address only) from that individual, inform the child that a parent's
            verifiable consent is required, and/or collect the email address of
            the user's parent in addition to the user's email address. We may
            use the parent's email address to seek the parent's verifiable
            consent or notify the parent of his/her child's online activities
            and enable the parent to unsubscribe his/her child from a newsletter
            or other similar activity. Once a parent provides consent, we may
            use any information collected from a child consistent with the rest
            of this Privacy Policy and/or the terms of the consent provided by
            the parent. If a user is under 13, we will not condition his/her
            participation in an online activity on the disclosure of more
            personal information than is reasonably necessary to participate in
            the activity. If you would like to review any personal information
            that we have collected online from your child, have this personal
            information deleted from our active servers, and/or request that
            there be no further collection or use of your child's personal
            information, or if you have questions about these information
            practices, you may contact us.
          </p>

          <h3>Changes to this Privacy Policy</h3>
          <p>
            We may modify this Privacy Policy at any time. If we make material
            changes to this Privacy Policy we will notify you by email (if we
            have your email address) or another reasonable manner through the
            Service. Any modifications to this Privacy Policy will be effective
            when posted (or as otherwise indicated at the time of posting).
          </p>

          <h3>Contact Us</h3>

          <p>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at: CINEFLARES 21650 W Oxnard St UNIT 350 Woodland
            Hills, CA 91367 United States Email: support@cineflares.com
          </p>

          <h3>Additional Information for European Union Users</h3>

          <p>
            Personal information - References to &ldquo;personal
            information&rdquo; in this Privacy Policy are equivalent to
            &ldquo;personal data&rdquo; governed by European data protection
            legislation. Controller and EU Representative CINEFLARES is the
            controller of your personal information covered by this Privacy
            Policy for purposes of the European data protection legislations and
            you can contact us using the contact details in the Contact section.
          </p>

          <p>
            Legal bases for processing - We only use your personal information
            as permitted by law. We are required to inform you of the legal
            bases of our processing of your personal information, which are
            described in the table below. If you have questions about the legal
            basis of how we process your personal information, contact us
            atsupport@cineflares.com.
          </p>

          <h3>Processing purpose - Legal basis</h3>
          <p>
            You have entered a contract with us and we need to use your personal
            information to provide services you have requested or take steps
            that you request prior to providing services. If you do not provide
            information indicated as required or mandatory within the Service,
            or that is otherwise necessary to provide a requested service or
            feature within the Service, it will not be available to you. To
            personalize the Service. To send you marketing communications. To
            create anonymous data. For security, compliance, fraud prevention
            and safety. Sharing your personal information as described in this
            Privacy Policy. These processing activities constitute our
            legitimate interests. We consider and balance potential impact on
            you and your rights before we process your personal information for
            our legitimate interests. We do not use your personal information
            for activities where the impact on you overrides our interests
            (unless we have your consent or are otherwise required or permitted
            to by law). For compliance with law; legal claims Processing is
            necessary to comply with our legal obligations.
          </p>

          <p>
            With your consent - Processing is based on your consent. Where we
            rely on your consent you have the right to withdraw it anytime in
            the manner indicated at the time consent is requested. Use for new
            purposes - We may use your personal information for reasons not
            described in this Privacy Policy where permitted by law and the
            reason is compatible with the purpose for which we collected it. If
            we need to use your personal information for an unrelated purpose,
            we will notify you and explain the applicable legal basis.
          </p>

          <p>
            Data export - If we transfer your personal information out of the
            European Economic Area in a manner that requires us to apply
            additional safeguards to your personal information under European
            data protection legislation, we will do so. Please contact us for
            information on any such transfers or the safeguards applied.
          </p>

          <p>
            Retention - We will only retain your personal information for as
            long as necessary to fulfill the purposes for which we collected it,
            including to satisfy legal, accounting, or reporting requirements.
            To determine the appropriate retention period for personal
            information, we consider the amount, nature, and sensitivity of the
            personal information, the potential risk of harm from unauthorized
            use or disclosure of your personal information, the purposes for
            which we process your personal information, whether we can achieve
            those purposes through other means, and the applicable legal
            requirements.
          </p>

          <p>Your rights - You may ask us to take the following actions:</p>
          <ul>
            <li>
              Opt-out Stop sending you direct marketing communications. You may
              continue to receive Service-related and other non-marketing
              communications.
            </li>
            <li>
              Access Provide you with information about our processing of your
              personal information and give you access to your personal
              information.
            </li>
            <li>
              Correct Update or correct inaccuracies in your personal
              information.
            </li>
            <li>Delete Delete your personal information.</li>
            <li>
              Transfer Transfer a machine-readable copy of your personal
              information to you or a third party of your choice.
            </li>
            <li>
              Export a machine-readable copy of your personal information that
              you can use with a different service.
            </li>
            <li>
              Object to our reliance on our legitimate interests as the legal
              basis of processing your personal information if that processing
              adversely impacts your legal rights.
            </li>
          </ul>
          <p>
            To request one of these actions, contact us using the details in the
            contact section above. We may request information from you to help
            us confirm your identity and process your request. Applicable law
            may require or permit us to decline your request. If we decline your
            request, we will tell you why, subject to legal restrictions. If you
            would like to submit a complaint about our use of your personal
            information or response to your requests regarding your personal
            information, please contact us. In addition, you may submit a
            complaint to the data protection regulator in your jurisdiction. You
            can find your data protection regulator here.
          </p>

          <h3>Cookies and Similar Technologies Notice</h3>
          <p>Last Updated: November 9, 2023</p>

          <p>
            Cookies are small data files stored in your browser by a website.
            They transmit information about your use of the site to its operator
            or to third parties that placed the cookie. Some cookies are
            &ldquo;session cookies&rdquo; that expire when you close your
            browser, and others are &ldquo;persistent cookies&rdquo; that stay
            on your computer or mobile device until you delete them. Our sites
            and applications use both session and persistent cookies to enable
            you to sign in and use our sites and applications, store your
            preferences and settings within them; analyze how they perform,
            personalize your experience with them, provide interest-based
            advertising, facilitate social media interaction and for other
            lawful purposes. We may also use other similar technologies for
            these purposes, such as web pixels that track browsing activity,
            social media widgets that facilitate interaction with social media
            platforms and unique device identifiers. In some cases, these
            cookies and similar technologies are used by third parties.
          </p>
          <p>
            We may collect information about traffic to our sites, and how our
            users use them, such as the number of visitors, the websites that
            referred them, the pages they visited, what time of day they
            visited, whether they have visited before, what features they used
            and other similar information. We use this information to help
            operate and improve our sites.
          </p>

          <p>
            Google Analytics. Learn more information about Google Analytics
            cookies here and about how Google protects your data here. You can
            prevent the use of Google Analytics relating to your use of our
            sites by downloading and installing the browser plugin available
            here.
          </p>

          <p>
            Advertising - Used by advertising companies to collect information
            about how you use our sites and other sites and applications over
            time. These companies use this information to show you ads they
            believe will be relevant to you within our sites and elsewhere, and
            to measure how the ads perform. Used by social media widgets that
            enable &ldquo;like&rdquo; buttons and allow you to share content
            within our sites and applications on social media. Your social media
            platform may be able to link information or actions about your
            interactions with our sites and applications to your account with
            them. Please see your social media platform&rsquo;s privacy policies
            for more details.
          </p>
          <p>
            Disabling cookies - Most browsers let you remove or reject cookies.
            To do this, follow the instructions in your browser settings. Many
            browsers accept cookies by default until you change your settings.
            For more information about cookies, including how to see what
            cookies have been set on your computer or mobile device and how to
            manage and delete them, visit www.allaboutcookies.org. If you do not
            accept our cookies, you may experience some inconvenience on our
            sites. For example, you may not be able to store preferences in our
            sites. If you disable cookies, certain features of our website may
            not work. For more information about targeting and advertising
            cookies and how you can opt out, you can visit the Network
            Advertising Initiative&rsquo;s opt-out page, the Digital Advertising
            Alliance&rsquo;s opt-out page, or http://youronlinechoices.eu.
          </p>

          <h3>California Residents - Your Privacy Rights</h3>
          <p>
            If you are a California resident, the California Consumer Privacy
            Act (&ldquo;CCPA&rdquo;) gives you additional rights about the
            collection, processing and sale of your personal information, which
            are explained below.
          </p>
          <p>Personal Information Collected</p>
          <p>
            We only collect the following categories of personal information:
          </p>
          <ul>
            <li>Identifiers;</li>
            <li>
              Characteristics of protected classifications under California and
              federal law (i.e. gender);
            </li>
            <li>Geolocation data;</li>
            <li>Internet/electronic activity;</li>
            <li>
              Commercial information (i.e. products purchased or viewed on our
              Site);
            </li>
            <li>
              Inferences drawn from the categories described above in order to
              create a profile about you to reflect your preferences,
              characteristics, behavior and attitudes.
            </li>
          </ul>
          <p>
            The specific pieces of personal information that we collect are set
            out in the &ldquo;Personal Information We Collect&rdquo; Section
            above. We will not collect additional categories of personal
            information or use personal information collected for additional
            purposes without providing you with notice.
          </p>
          <p>Purposes for Use of your Information</p>
          <p>
            In order to learn about how we use your personal information, see
            the &ldquo;How We Use Your Personal Information&rdquo; Section
            above.
          </p>
          <p>Personal Information Disclosed for Business Purposes</p>
          We may disclose the following categories of personal information for
          business purposes, for example, with our service providers as
          discussed above:
          <ul>
            <li>Identifiers</li>
            <li>
              Characteristics of protected classifications under California and
              federal law (i.e. gender)
            </li>
            <li>Geolocation data</li>
            <li>Internet/electronic activity</li>
            <li>
              Commercial information (i.e. services viewed or purchased on our
              Site)
            </li>
            <li>
              Inferences drawn from the categories described above in order to
              create a profile about you to reflect your preferences,
              characteristics, behavior and attitudes
            </li>
          </ul>

          For more information, see the &ldquo;How We Use Your Personal
          Information&rdquo; Section above.

          <p>Your Rights Under CCPA</p>

          Additionally, you have the following rights under the CCPA.
          Instructions on how to exercise each right are listed below. You may
          only exercise each right up to two times in any twelve (12) month
          period. To exercise more than one right or to submit more than one
          email address, please submit each request individually. If you submit
          multiple requests, we cannot guarantee the order in which your
          requests will be processed:

          <ul>
            <li>
              Right to Know: You have the right to know what categories of
              personal information we collected in the preceding twelve (12)
              months, including the categories of sources from which the
              personal information was collected, the specific pieces of
              personal information we have collected about you, and the business
              or commercial purposes for which such personal information was
              collected and shared. You also have the right to know the
              categories of personal information which were disclosed for
              business purposes, and the categories of third parties with whom
              we shared your personal information in the preceding twelve (12)
              months.
              <ul>
                <li>
                  To exercise your right to know, you may or email us at
                  support@cineflares.com and follow these instructions:
                  <ul>
                    <li>
                      Include &ldquo;Right to Know Under CCPA&rdquo; in the
                      subject of the email and
                    </li>
                    <li>
                      Please include sufficient personal information for us to
                      locate your records and to verify the identity affiliated
                      with your account. At a minimum, you must provide the full
                      name and email address affiliated with your account. We
                      may request additional information to complete the
                      verification process if we are unable to verify your
                      identity initially. Our ability to locate your personal
                      information is limited by the accuracy and the
                      completeness of the information that you provide.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Right to Access: You have the right to receive the personal
              information that you gave us. The information that we will provide
              to you will be masked, meaning that portions of it will be omitted
              so that it can&rsquo;t be used fraudulently. For example, your
              telephone number may display as (123)-XXX-XX567. In order to
              exercise your right to access, you may email us at
              support@cineflares.com and follow these instructions:
              <ul>
                <li>
                  Write to us from the email address that is affiliated with
                  your account and include &ldquo;Right to Access Personal
                  Information Under CCPA&rdquo; in the subject of the email or
                  written request; and
                </li>
                <li>
                  Please include sufficient personal information for us to
                  locate your records and to verify the identity affiliated with
                  your account. At a minimum, you must provide the full name and
                  email address affiliated with your account. We may request
                  additional information to complete the verification process if
                  we are unable to verify your identity initially. Our ability
                  to locate your personal information is limited by the accuracy
                  and the completeness of the information that you provide. For
                  example, if you use multiple email addresses and you include
                  only one in your request, we may only locate the information
                  associated with the email address that you included in the
                  request.
                </li>
              </ul>
            </li>
            <li>
              Deletion: You can request to have your personal information
              deleted from our systems and we will ask our service providers to
              do the same.
              <ul>
                <li>
                  Consequences of Deletion: Please note that if we delete your
                  personal information, many of our Services will not work the
                  same. For example, you will not have an account (because any
                  prior login information and saved transaction data will be
                  deleted) and so your addresses and other information will no
                  longer be available. If you make multiple requests under this
                  section, we recommend sending your deletion request last, as
                  we will not be able to fulfill your other requests once we
                  have deleted your information.
                </li>
                <li>
                  Exceptions: We may not be able to fulfill your request if we
                  (or our service providers) are required to retain your
                  personal information for one or more of the following reasons:
                  <ul>
                    <li>
                      Transactional: to complete a transaction for which the
                      personal information was collected, provide a service or
                      good requested by you, or perform a contract we have with
                      you;
                    </li>
                    <li>Security: to detect data security incidents;</li>
                    <li>Error Correction: to debug or repair any errors;</li>
                    <li>
                      Legal: to protect against fraud or illegal activity or to
                      comply with applicable law or a legal obligation, or
                      exercise rights under the law, such as the right to free
                      speech; or
                    </li>
                    <li>
                      Internal Use: to use your personal information,
                      internally, in a lawful manner that is compatible with the
                      context in which you provided the information (i.e. to
                      improve our services).
                    </li>
                  </ul>
                </li>
                <li>
                  In order to exercise your right to deletion, email us
                  atsupport@cineflares.com using the email address affiliated
                  with your account and follow these instructions:
                  <ul>
                    <li>
                      In the subject of you request, please write &ldquo;Right
                      to Deletion Under CCPA&rdquo;;
                    </li>
                    <li>
                      Please include sufficient personal information for us to
                      locate your records and to verify the identity affiliated
                      with your account. At a minimum, you must provide the full
                      name and email address affiliated with your account. We
                      may request additional information to complete the
                      verification process if we are unable to verify your
                      identity initially. Our ability to locate your personal
                      information is limited by the accuracy and the
                      completeness of the information that you provide. For
                      example, if you use multiple email addresses and you
                      include only one in your request, we may only delete the
                      email address that you included in the request.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Non-Discrimination: We will not discriminate against you for
              exercising any of your rights under CCPA and we will not deny you
              services or goods, charge you a different price, or provide you
              with a lesser quality of goods or services if you exercise any of
              your rights.
            </li>
          </ul>
        </div>
      </section>
      <div class="column right"></div>
    </div>
  </div>
</template>
<script setup>
import "../styles/Policy.css";
import { useScreenStore } from "@/stores/Screen";
import { useRouter } from "vue-router";
import { onMounted, onUnmounted } from "vue";
import { useUsersStore } from "@/stores/Users";

const router = useRouter();

const store = useScreenStore();

const userStore = useUsersStore();

onMounted(() => {
  store.checkWindowSize();
  document.querySelector("body").classList.add("backgroundImage");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("backgroundImage");
});
function exitPage() {
  router.push("/");
}
</script>
