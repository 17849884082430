<template>
  <div class="select-col" @contextmenu.prevent="disableRightClick">
    <SaveBtn
      :status="savedOnWishlist ? 'saved' : 'unsaved'"
      @click="handleWishlistClick"
      :loading="loading"
      :viewCondition="true"
    />
    <div @click="emit('openDetailsPopup', browse.id)" class="select-box">
      <div class="select-image">
        <img
          :src="browse.flareImage ? browse.flareImage : browse.preview"
          alt=""
        />
      </div>
      <div class="select-details-box">
        <div class="select-details">
          <span class="select-lens-name">{{ browse.name }}</span>
          <span class="select-focal-length">{{ browse.focalLength }}</span>
          <span class="select-transmission">{{ browse.transmission }}</span>

          <img :src="browse.icon" alt="" />
        </div>
      </div>
    </div>
    <!--  <img
      src="@/assets/img/dots.svg"
      alt=""
      class="select-dots"
      @click="showActions = !showActions"
      ref="actionRef"
    />-->
    <Transition name="expand">
      <div class="select-col-actions" v-if="showActions">
        <span @click="exportToPDF">Save PDF</span>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import SaveBtn from "../Selects/SaveBtn.vue";
import { disableRightClick } from "@/includes/helper";

const props = defineProps([
  "browse",
  "savedOnWishlist",
  "saveLoading",
  "pendingArray",
]);

const emit = defineEmits([
  "removeSelect",
  "renderPdf",
  "openDetailsPopup",
  "addToWishlist",
  "removeFromWishList",
]);

//const actionRef = ref();

const showActions = ref(false);

const exportToPDF = () => {
  emit("renderPdf", props.select.id);
};

const loading = computed(() => {
  return props.pendingArray.some((item) => item === props.browse.id);
});

//const handleActionDropdown = (e) => {
//if (actionRef.value.contains(e.target)) return;
//
//if (showActions.value) {
//  showActions.value = false;
//}
//};//

const handleWishlistClick = () => {
  if (props.savedOnWishlist) {
    emit("removeFromWishList", props.browse.id);
  } else {
    emit("addToWishlist");
  }
};

//onMounted(() => {
//  window.addEventListener("click", handleActionDropdown);
//});
//onUnmounted(() => {
//  window.removeEventListener("click", handleActionDropdown);
//});
</script>

<style scoped>
.select-image {
  border: 1px solid #5f5f5f;
  margin-bottom: 5px;
  overflow: hidden;
}
.select-image img {
  display: block;
  transition: all 0.3s;
}
.select-details-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.8;
  letter-spacing: 1px;
}
.select-box {
  cursor: pointer;
  text-decoration: none;
}

.select-box:hover .select-image img {
  transform: scale(1.1);
}
.select-lens-name {
  text-transform: uppercase;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.select-details {
  display: flex;
  gap: 10px;
  align-items: center;
}
.select-details img {
  height: 40px;
}
.select-dots {
  width: 18px;
  opacity: 0.75;
  cursor: pointer;
  position: absolute;
  top: calc(100% - 30px);
  right: 0;
}

.select-col {
  flex-basis: calc(
    (100% - 80px) / 3
  ); /* for gap of 40px * 2 elements, and 3 elements per row */
  position: relative;
}

.select-col-actions {
  position: absolute;
  top: 97%;
  right: 0;
  border: 1px solid #145aaa;
  padding: 7px 10px;
  text-transform: uppercase;
  font-size: 10px;
  transform-origin: top;
  color: #ffffff;
  opacity: 0.8;
  cursor: pointer;
}

.unselect-box {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.603);

  padding: 7px;
  background-color: #04152a;
  font-size: 12px;
  cursor: pointer;
  z-index: 2;
}
.unselect-box img {
  width: 12px;
  opacity: 0.6;
}

.unselect-box-flex {
  display: flex;
  align-items: start;
  gap: 5px;
}

@media screen and (max-width: 1200px) {
  .select-col {
    flex: 1 1 45%;
  }
}
</style>
