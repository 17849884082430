import { defineStore } from "pinia";
export const useScreenStore = defineStore("screen", {
  state: () => ({
    isMobile: null,
  }),
  getters: {},
  actions: {
    checkWindowSize() {
      if (window.innerWidth <= 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
});
