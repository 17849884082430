<template>
  <div id="messageAlert" class="bounce-in-right" v-if="toastStore.toastVisible">
    <div class="message-alert-box">
      <div class="message-alert-close">
        <img
          :src="crossPath"
          alt=""
          id="messageAlertClose"
          @click="toastStore.hideToast()"
        />
      </div>
      <div class="message-icon">
        <img
          :src="
            toastStore.toastType === 'success'
              ? imagePathSuccess
              : imagePathError
          "
          alt=""
        />
      </div>
      <div class="message-details">
        <h3 class="message-details-head">
          {{ toastStore.toastType === "success" ? "Success" : "Error" }}
        </h3>
        <p class="message-detail">{{ toastStore.toastMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useToastStore } from "@/stores/Toast";

const imagePathSuccess = computed(() => {
  // Using `require` for dynamic imports in Vue
  return require(`@/assets/img/tickmark.svg`);
});

const imagePathError = computed(() => {
  // Using `require` for dynamic imports in Vue
  return require(`@/assets/img/error.svg`);
});

const crossPath = computed(() => {
  // Using `require` for dynamic imports in Vue
  return require(`@/assets/img/crossmark.svg`);
});

const toastStore = useToastStore();
</script>

<style scoped>
#messageAlert {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 20;
}

.message-alert-box {
  display: flex;
  background: white;
  align-items: center;
  gap: 15px;
  padding: 0 20px;
  border-radius: 10px;
}

.message-icon img {
  width: 45px;
  display: block;
}

.message-details-head {
  color: black;
  font-size: 18px;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}

.message-detail {
  color: gray;
  margin-top: 3px;
}
.message-alert-close {
  position: absolute;
  top: 5px;
  right: 10px;
}
.message-alert-close img {
  width: 10px;
  cursor: pointer;
}

.hide-message-alert {
  display: none;
}

.bounce-in-right {
  animation: bounce-in-right 1.5s ease;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
