import html2pdf from "html2pdf.js";

const exportToPDF = async (pdfRefValue, select) => {
  var element = pdfRefValue;
  var opt = {
    margin: 0,
    filename: `${select.value.name}-${select.value.focalLength}-${select.value.transmission}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
      dpi: 300,
      letterRendering: true,
      allowTaint: true,
      useCORS: true,
      scale: 2,
    },
    jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
  };

  // New Promise-based usage:
  await html2pdf().set(opt).from(element).save();
};

export default exportToPDF;
