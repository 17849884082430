<template>
  <div class="dash-tab">
    <div class="profile-box">
      <VeeForm
        :validation-schema="schema"
        class="account-form"
        @submit="handleSubmit"
      >
        <div class="form-row">
          <div class="form-col account-form-col">
            <label for="lastName">Email</label>
            <VeeField
              type="text"
              v-model.trim="userData.email"
              id="email"
              name="email"
              disabled
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-col account-form-col">
            <label for="firstName">First Name</label>
            <VeeField
              name="firstName"
              v-model.trim="userData.firstName"
              type="text"
              id="firstName"
            />
            <ErrorMessage class="form-error" name="firstName" />
          </div>
          <div class="form-col account-form-col">
            <label for="lastName">Last Name</label>
            <VeeField
              name="lastName"
              v-model.trim="userData.lastName"
              type="text"
              id="lastName"
            />
            <ErrorMessage class="form-error" name="lastName" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-col account-form-col">
            <label for="firstName">Industry</label>

            <VeeField as="select" v-model="userData.industry" name="industry">
              <option value="cinematographer">Cinematographer</option>
              <option value="director">Director</option>
              <option value="vfxArtist">VFX Artist</option>
              <option value="vfxVendor">VFX Vendor</option>
              <option value="cameraRentalHouse">Camera Rental House</option>
              <option value="lensManufacturer">Lens Manufacturer</option>
              <option value="press">Press</option>
              <option value="filmStudent">Film Student</option>
              <option value="other">Other</option>
            </VeeField>
            <ErrorMessage class="form-error" name="industry" />
          </div>
          <div class="form-col account-form-col">
            <label for="company">Company</label>
            <VeeField
              type="text"
              v-model.trim="userData.company"
              name="company"
              id="company"
            />
            <ErrorMessage class="form-error" name="company" />
          </div>
        </div>
        <div class="form-row custom-checkbox">
          <div class="form-col">
            <!----><VeeField
              type="checkbox"
              id="subscribed"
              name="subscribed"
              v-model="userData.subscribed"
              value="on"
              :checked="userData.subscribed"
            /><label for="subscribed"
              ><div class="pseudo-checkbox"></div>
              <span>
                Get emails when we add new lenses and features
              </span></label
            >
          </div>
        </div>
        <div class="form-button-row">
          <button
            :disabled="!formChanged"
            type="submit"
            class="account-submit account-button"
          >
            Update
          </button>
          <button
            type="button"
            @click="handleCancel"
            class="account-button account-cancel"
          >
            Cancel
          </button>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script setup>
import client from "@/api/client";
import { useOverlayStore } from "@/stores/Overlay";
import { useToastStore } from "@/stores/Toast";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const overlayStore = useOverlayStore();
const toastStore = useToastStore();

const userData = ref({
  firstName: "",
  lastName: "",
  email: "",
  industry: "",
  company: "",
  subscribed: "off",
});

const originalFormData = ref();

const schema = {
  firstName: "required|min:3|max:100|alpha_spaces",
  lastName: "required|min:3|max:100|alpha_spaces",
  email: "required|min:3|max:100|email",
  industry: "required",
  company: "required",
};

const getProfile = async () => {
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}/users/profile`
    );

    if (response.status === 200) {
      userData.value.id = response.data.id;
      userData.value.email = response.data.email;
      userData.value.firstName = response.data.firstName;
      userData.value.lastName = response.data.lastName;
      userData.value.industry = response.data.industry;
      userData.value.company = response.data.company;
      userData.value.subscribed = response.data.hasSubscribedForNewsletter
        ? "on"
        : undefined;

      originalFormData.value = { ...userData.value };
    }
  } catch (err) {
    console.log(err);
    toastStore.showToast(
      "error",
      "Something went wrong. Please try again later."
    );
  }
};

const formChanged = computed(() => {
  return (
    JSON.stringify(userData.value) !== JSON.stringify(originalFormData.value)
  );
});

const handleSubmit = async () => {
  overlayStore.showLoadingOverlay();

  const payload = {
    firstName: userData.value.firstName,
    lastName: userData.value.lastName,
    company: userData.value.company,
    industry: userData.value.industry,
    hasSubscribedForNewsletter:
      userData.value.subscribed === "on" ? true : false,
  };

  try {
    const response = await client.put(
      `${process.env.VUE_APP_BASE_URL}/users/profile/update`,
      payload
    );

    if (response.status === 200) {
      toastStore.showToast("success", "Profile info updated.");
      getProfile();
    } else {
      toastStore.showToast(
        "error",
        "Something went wrong. Please try again later."
      );
    }
  } catch (err) {
    console.log(err);
    toastStore.showToast(
      "error",
      "Something went wrong. Please try again later."
    );
  }

  overlayStore.hideLoadingOverlay();
};

const handleCancel = () => {
  router.push("/");
};

onMounted(() => {
  getProfile();
});
</script>

<style scoped>
.secondary-title {
  margin-top: 0;
}

.form-error {
  margin-left: 0;
}
</style>
