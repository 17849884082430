import { defineStore } from "pinia";
import { getWishlist } from "@/api/wishlist";
export const useWishlistStore = defineStore("wishlist", {
  state: () => ({
    wishlist: [],
  }),
  getters: {},
  actions: {
    async fetchWishlist() {
      this.wishlist = await getWishlist();
    },
    addToWishlist(newWishItem) {
      this.wishlist = [...this.wishlist, newWishItem];
    },
    removeFromWishlist(wishListID) {
      const filteredArray = this.wishlist.filter(
        (wishlist) => wishlist.id !== wishListID
      );
      this.wishlist = [...filteredArray];
    },
  },
});
