<template>
  <div class="selectsBody secondary-body">
    <div class="container newsbody-container logo-container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <section id="selects-section">
      <div class="container">
        <div class="column left"></div>
        <div class="column middle">
          <h3 class="secondary-title">Selects</h3>
          <div class="folders-row">
            <SelectFolder @refreshFolderData="fetchWishlistFolders" type="create" />
            <SelectFolder
              v-for="folder in wishlistFolders"
              :key="folder.id"
              :folder="folder"
              @dragover.prevent
              :class="['drop-box', { highlight: currentBoxId === folder.id }]"
              @drop="onDrop(folder.id)"
              @dragenter="onDragEnter(folder.id)"
              @dragleave="onDragLeave"
            />
          </div>
          <div class="select-row" v-if="savedVideosWithNoFolder.length > 0">
            <SelectCard
              v-for="(select, index) in savedVideosWithNoFolder"
              :key="select.id"
              :select="select"
              :selectIndex="index"
              @removeSelect="handleSelectRemove"
              @renderPdf="renderPdfContents"
              @refreshFolderData="fetchWishlistFolders"
              :folders="wishlistFolders"
              draggable="true"
              @dragstart="onDragStart($event, select)"
              @dragend="onDragEnd"
            >
            </SelectCard>
          </div>
        </div>

        <div class="column right"></div>
      </div>
    </section>

    <img src="../assets/img/add-folder.svg" ref="dragImage" class="custom-drag-image" />

    <div v-if="selectPdfDetails" style="display: none">
      <div ref="pdfRef">
        <SelectPDF :select="selectPdfDetails" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import SelectCard from "@/components/Selects/SelectCard.vue";
import SelectPDF from "@/components/Selects/SelectPDF.vue";
import savePdf from "@/includes/savePdf";
import SelectFolder from "@/components/Selects/SelectFolder.vue";
import { useRouter } from "vue-router";
import { useOverlayStore } from "@/stores/Overlay";
import { useUsersStore } from "@/stores/Users";
import { getWishlist } from "@/api/wishlist";
import client from "@/api/client";
import { addWishlistToFolders, getFolders } from "@/api/wishlist-folder";
const router = useRouter();

const overlayStore = useOverlayStore();

const savedVideos = ref([]);
const wishlistFolders = ref([]);
const selectPdfDetails = ref(null);
const pdfRef = ref(null);
const draggedSelect = ref(null);
const currentBoxId = ref(null);
const dragImage = ref(null);

const userStore = useUsersStore();

const fetchSavedVideos = async () => {
  const data = await getWishlist();

  savedVideos.value = data;
};

// Compute the IDs of wishlists in folderData
const folderWishlistIds = computed(() => {
  return wishlistFolders.value.flatMap((folder) =>
    folder.wishlists.map((item) => item.id)
  );
});

// Compute wishlists that are not in folderData
const savedVideosWithNoFolder = computed(() => {
  return savedVideos.value.filter(
    (wishlist) => !folderWishlistIds.value.includes(wishlist.id)
  );
});

const handleSelectRemove = (selectIndex) => {
  savedVideos.value.splice(selectIndex, 1);
};

const convertToBase64 = async (url) => {
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}/image/base64?url=${url}`
    );

    if (response.data.code === "auth/id-token-expired") {
      router.push("/login");
      return;
    }
    return response.data.base64;
  } catch (err) {
    console.log(err);
  }
};

const fetchSelectDetails = async (id) => {
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}/wishlist/id/${id}`
    );

    if (response.data.code === "auth/id-token-expired") {
      router.push("/login");
      return;
    }

    const flareImageBase64 = await convertToBase64(
      response.data.sceneImageUrl || response.data.flareImage
    );
    const iconBase64 = await convertToBase64(response.data.wishlist.icon);

    selectPdfDetails.value = response.data;
    selectPdfDetails.value.flareImageBase64 = flareImageBase64;
    selectPdfDetails.value.iconBase64 = iconBase64;
  } catch (err) {
    console.log(err);
  }
};

const renderPdfContents = async (id) => {
  overlayStore.showLoadingOverlay();
  await fetchSelectDetails(id);
  await savePdf(pdfRef.value, selectPdfDetails);

  overlayStore.hideLoadingOverlay();
};

const fetchWishlistFolders = async () => {
  const data = await getFolders();

  if (data) {
    wishlistFolders.value = data;
  }
};

const onDragStart = (event, product) => {
  draggedSelect.value = product;
  document.body.classList.add("dragging");

  if (dragImage.value) {
    // Use the custom drag image
    //event.dataTransfer.setDragImage(dragImage.value, 0, 0);
  }
};

const onDragEnd = () => {
  document.body.classList.remove("dragging");
  currentBoxId.value = null;
};

const onDrop = async (folderId) => {
  if (draggedSelect.value) {
    const data = await addWishlistToFolders([folderId], draggedSelect.value.id);
    if (data) await fetchWishlistFolders();
    draggedSelect.value = null;
  }
};

const onDragEnter = (boxId) => {
  if (draggedSelect.value) {
    currentBoxId.value = boxId;
  }
};

const onDragLeave = (event) => {
  // Only set currentBoxId to null if leaving the actual box element
  if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
    currentBoxId.value = null;
  }
};

onMounted(async () => {
  await fetchWishlistFolders();
  await fetchSavedVideos();
});
</script>

<style scoped>
.middle {
  flex: 5;
  display: block;
}

.select-row {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .logo-container {
    display: none;
  }

  .secondary-body {
    padding-top: 0;
    min-height: 70vh;
  }
}

.folders-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .folders-row {
    max-width: calc(100vw - 40px); /* -40 for the margins of secondary-body */
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .folder-box {
    min-width: 220px;
  }
}

.custom-drag-image {
  position: absolute;
  top: -9999px;
  left: -9999px;
  padding: 10px;
  width: 50px;
  pointer-events: none;
}

/* Custom cursor for dragging */
.dragging {
  /* cursor: url("../assets/img/add-folder.svg") !important; */
}
</style>
