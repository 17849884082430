<template>
  <div autofocus>
    <AlertBox v-if="showPremiumAlert" @closeAlert="showPremiumAlert = false">
      <div class="premium-upgrade-box">
        <img src="@/assets/img/premium-icon.svg" alt="" />
        <h4 class="gradient-text">Pro feature only</h4>
        <p>
          Upgrade to PRO account to unlock more features and access to our full
          PRO library.
        </p>
        <RouterLink class="pricing-link" to="/pricing">View Plans</RouterLink>
      </div>
    </AlertBox>
    <div
      class="loading-spinner-overlay"
      id="loading-spinner-overlay"
      ref="loadingSpinnerRef"
      style="display: none"
    >
      <div class="loading-spinner"></div>
    </div>

    <MobileSearch />

    <div class="heading">
      <div class="icon">
        <img style="display: none" id="iconImage" ref="iconRef" />
      </div>
      <div class="focalLength">
        <select
          id="focalLength"
          name="focalLength"
          ref="focalLengthRef"
          style="display: none"
          @change="setFocalLengthChange($event)"
        >
          <option
            v-for="option in store.focalLengthOptionsRight"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="transmission">
        <select
          id="transmission"
          name="transmission"
          ref="transmissionRef"
          style="display: none"
          @change="setTransmissionChange($event)"
        >
          <option
            v-for="option in store.transmissionsRight"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
    <div class="video-container" ref="videoContainerRef">
      <div class="overlay" @click="playVideo()"></div>
      <SaveBtn
        :status="saveBtnStatus"
        :loading="saveBtnLoading"
        @click="addWishlist"
        :viewCondition="showSaveIcon"
      />

      <div
        class="spinner-container"
        id="spinner"
        style="display: none"
        ref="spinnerRef"
      >
        <div class="spinner-load"></div>
      </div>
      <video
        id="video"
        class="mob_video"
        playsinline
        oncontextmenu="return false;"
        preload="auto"
        ref="videoRef"
        @click="playVideo()"
        @ended="reloadPage()"
        disablePictureInPicture
        controlsList="nodownload noplaybackrate"
        @play="setVideoPlay"
        @pause="setVideoPause"
        @timeupdate="updateProgressBar"
      >
        <source src="" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="video-controls video-control-mobile">
        <Transition name="fade">
          <div class="video-timeline-bar" v-show="showTimeline">
            <div class="video-timeline-progress" ref="progressBar"></div>
          </div>
        </Transition>
        <Transition name="fade">
          <div
            v-show="showFullscreen"
            class="video-control-option video-full-screen"
            @click.prevent="showFullScreen()"
          >
            <img
              src="@/assets/img/fullscreen.svg"
              width="12"
              class="fullscreen-img"
            />
          </div>
        </Transition>
      </div>
      <div
        class="play-button-img"
        id="playButton"
        ref="playButton"
        @click="playVideo()"
        v-if="!videoPlaying"
      >
        <img src="../../assets/img/play.png" />
      </div>
    </div>
    <div class="lenslist-container">
      <div class="lensesList">
        <ul
          class="viewport"
          ref="viewport"
          @scroll="() => store.snapToClosestOption(viewport, true)"
        >
          <li v-for="item in store.data" :key="item.id">
            <a class="nav-title nav-entity">{{ item.name }}</a>
            <ul class="nested-list" v-if="item.lenses && item.lenses.length">
              <li
                v-for="lens in uniqueLenses(item.lenses)"
                :key="getLinkId(lens.name)"
                class="nav-item nav-entity"
              >
                <a
                  @click="setSelection(lens.name)"
                  :id="getLinkId(lens.name)"
                  href="javascript:;"
                  class="nav-link"
                  >{{ lens.name }}</a
                >
                <span class="new-badge" v-if="lens.isNew">NEW</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-details-container">
      <DetailsContainer
        side="right"
        v-if="singleVideoDetail"
        :videoDetails="singleVideoDetail"
        @showDetails="handleDetailsShow"
        :showLensDetails="showLensDetails"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch, ref, onUnmounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLensesStore } from "@/stores/Lenses";
import MobileSearch from "./MobileSearch.vue";
import "../../styles/MobileComponents/MiddleContainer.css";
import { ampli } from "@/ampli";
import SaveBtn from "@/components/Selects/SaveBtn.vue";
import DetailsContainer from "../DetailsContainer.vue";
import AlertBox from "../AlertBox.vue";
import { useWishlistStore } from "@/stores/Wishlist";
import { addToWishlist, removeFromWishlist } from "@/api/wishlist";
import { useRouter } from "vue-router";

const store = useLensesStore();
const focalLengthRef = ref(null);
const transmissionRef = ref(null);
const iconRef = ref(null);
const spinnerRef = ref(null);
const videoRef = ref(null);
const loadingSpinnerRef = ref(null);
const playButton = ref(null);
const viewport = ref(null);
const videoPlaying = ref(false);
const videoContainerRef = ref(null);
const progressBar = ref(null);
const showTimeline = ref(false);
const showFullscreen = ref(true);
const showSaveIcon = ref(true);
const singleVideoDetail = ref(null);
const saveBtnStatus = ref(null);
const saveBtnLoading = ref(false);
const showLensDetails = ref(false);
const showPremiumAlert = ref(false);

const { SelectedVideo, SelectedFocalLength, SelectedTransmission } =
  storeToRefs(store);

const wishlistStore = useWishlistStore();

const savedVideos = computed(() => wishlistStore.wishlist);

function showFullScreen() {
  store.enterFullscreen(videoRef);
}

const router = useRouter();

let currentPathObject = router.currentRoute.value;

onMounted(async () => {
  await store.fetchData();
  store.setSelectedVideo(
    store.defaultMobileVideoId || process.env.VUE_APP_DEFALULT_LENS
  );
  store.addHeightOntopAndBottom(viewport);
});

onUnmounted(() => {
  store.SelectedVideo = null;
  store.SelectedFocalLength = null;
});

function getLinkId(name) {
  // Convert to lowercase and replace spaces with hyphens
  return name.toLowerCase().replace(/\s+/g, "-");
}

function setSelection(lens) {
  store.setSelectedVideo(lens.replace(/\s+/g, "-").toLowerCase());
}

const uniqueLenses = (lensCategory) => {
  if (lensCategory) {
    const uniqueLenses = {};

    // Iterate through lenses in the category
    lensCategory.forEach((lens) => {
      const name = lens.name;
      const isNew = lens.isNew;

      // If lens name is not already in uniqueLenses or isNew
      if (!uniqueLenses[name] || isNew) {
        uniqueLenses[name] = isNew;
      }
    });

    // Convert uniqueLenses object to an array of objects
    const uniqueLensArray = Object.keys(uniqueLenses).map((name) => ({
      name,
      isNew: uniqueLenses[name],
    }));

    return uniqueLensArray;
  }
  return [];
};

function setFocalLengthChange(event) {
  const selected = event.target.value;
  store.setSelectedFocalLength(selected);
}
function setTransmissionChange(event) {
  const selected = event.target.value;
  store.setSelectedTransmission(selected);
}

function reloadTransmission() {
  store.SelectedVideoDetails.sort((a, b) => {
    const focalLengthA = a.focalLength
      ? parseFloat(a.focalLength.replace("mm", ""))
      : 0;
    const focalLengthB = b.focalLength
      ? parseFloat(b.focalLength.replace("mm", ""))
      : 0;
    const transmissionA = a.transmission
      ? parseFloat(a.transmission.replace("T", ""))
      : 0;
    const transmissionB = b.transmission
      ? parseFloat(b.transmission.replace("T", ""))
      : 0;
    const focalDiff = focalLengthA - focalLengthB;
    if (focalDiff !== 0) {
      return focalDiff;
    }
    return transmissionA - transmissionB;
  });
  const transmissonOptions = store.SelectedVideoDetails.filter(
    (s) => s.focalLength === store.SelectedFocalLength
  );

  // delete previous transmissions and set a new one
  const transmissionElement = document.getElementById("transmission");

  while (transmissionElement.options.length > 0) {
    transmissionElement.remove(0);
  }

  store.setSelectedTransmission(transmissonOptions[0]?.transmission);

  transmissonOptions?.forEach((option) => {
    const optionElement = document.createElement("option");
    optionElement.value = option.transmission;
    optionElement.text = option.transmission;

    // optionElement.selected =
    //   option.transmission === selectedTransmission ? "selected" : "";
    transmissionElement.appendChild(optionElement);
  });
  transmissionElement.style.display = "block";
}

/*
AMPLITUDE: Track lens watched
Triggers when video is played.
*/
const trackLensesWatched = () => {
  // const selectedLensRight = store.RightSelectedVideoDetails.find(
  //   (s) =>
  //     s.focalLength === store.RightFocalLength &&
  //     s.transmission === store.RightTransmission
  // );
  const selectedLens = store.SelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.SelectedFocalLength &&
      s.transmission === store.SelectedTransmission
  );

  ampli.lensVideoPlayed({
    leftLensId: selectedLens?.id,
    leftLensName: selectedLens?.name,
    leftLensCategoryId: selectedLens?.lensCategoryId,
    leftFocalLength: selectedLens?.focalLength,
    leftTransmission: selectedLens?.transmission,
  });
};

const setVideoPlay = () => {
  videoPlaying.value = true;
  showTimeline.value = true;
  showFullscreen.value = true;

  if (videoControlsTIimeout) clearTimeout(videoControlsTIimeout);

  videoControlsTIimeout = setTimeout(() => {
    showFullscreen.value = false;
    showTimeline.value = false;
    showSaveIcon.value = false;
  }, 3000);
};
const setVideoPause = () => {
  videoPlaying.value = false;
  showTimeline.value = true;
  showFullscreen.value = true;
  showSaveIcon.value = true;

  clearTimeout(videoControlsTIimeout);
};

let videoControlsTIimeout;

function playVideo() {
  const video = document.getElementById("video");

  // Check if the video is paused or ended before playing
  if (video.paused || video.ended) {
    video.play();
    trackLensesWatched();
  } else {
    // Pause the video if it's currently playing
    video.pause();
  }
}

function reloadPage() {
  const video1 = document.getElementById("video");
  video1.load();
  videoPlaying.value = false;
}

// Progress bar on custom timeline
const updateProgressBar = (e) => {
  const { currentTime, duration } = e.target;
  const percetageUpdate = (currentTime / duration) * 100;
  progressBar.value.style.width = `${percetageUpdate}%`;
};

const resetVideoControls = () => {
  showTimeline.value = false;
  clearTimeout(videoControlsTIimeout);

  showFullscreen.value = true;
  showSaveIcon.value = true;
  progressBar.value.style.width = `0%`;
};

const handleUnsave = async (lensId) => {
  const wishlistData = savedVideos.value.find(
    (wishlist) => wishlist.lensId === lensId && !wishlist.sceneImageUrl
  );

  await removeFromWishlist(wishlistData.id);

  wishlistStore.removeFromWishlist(wishlistData.id);

  saveBtnLoading.value = false;
};

const addWishlist = async () => {
  let videoObject;
  let status;
  let lensCategoryName;

  videoObject = singleVideoDetail.value;
  saveBtnLoading.value = false;
  status = saveBtnStatus.value;

  lensCategoryName = store.data.find(
    (lens) => lens.id === singleVideoDetail.value?.lensCategoryId
  )?.name;

  const {
    id: lensId,
    name,
    focalLength,
    transmission,
    preview,
    flareImage,
    videoPath,
  } = videoObject;

  saveBtnLoading.value = true;
  if (status === "saved") {
    handleUnsave(lensId);
    return;
  }

  const payload = {
    lensId,
    name,
    focalLength,
    transmission,
    flareImage: flareImage ? flareImage : preview,
    videoPath,
    categoryName: lensCategoryName,
  };

  /*
    AMPLITUDE: Track lens saved
    Triggers when a lens save button is clicked
  */
  ampli.lensSaved({
    lensId: lensId,
    lensName: name,
    focalLength: focalLength,
    transmission: transmission,
    page: currentPathObject?.fullPath,
  });

  const response = await addToWishlist(payload);

  if (response.status === 200) {
    wishlistStore.wishlist = [...wishlistStore.wishlist, response.data];
  }

  saveBtnLoading.value = false;
};

const generateSaveIcon = () => {
  if (!singleVideoDetail.value) return;
  const { id } = singleVideoDetail.value;

  let idArray = [];

  savedVideos.value?.forEach((obj) => {
    if (!obj.sceneImageUrl) idArray.push(obj.lensId);
  });

  if (idArray.includes(id)) {
    saveBtnStatus.value = "saved";
  } else {
    saveBtnStatus.value = "unsaved";
  }
};

const handleDetailsShow = () => {
  if (store.isPremiumUser) {
    showLensDetails.value = !showLensDetails.value;
  } else {
    showPremiumAlert.value = true;
  }
};

watch(savedVideos, () => {
  generateSaveIcon();
});

watch(singleVideoDetail, () => {
  generateSaveIcon();

  const lensCategoryName = store.data.find(
    (lens) => lens.id === singleVideoDetail.value?.lensCategoryId
  )?.name;

  if (lensCategoryName) {
    singleVideoDetail.value.lensCategoryName = lensCategoryName;
  }
});

watch(SelectedVideo, () => {
  store.setSelectedVideoDetails();
  reloadPage();

  const listItem = document.getElementById(
    store.SelectedVideo.toLowerCase().replace(/\s+/g, "-")
  );

  if (store.searchIsActive) {
    const navEntities = document.querySelectorAll(".nav-entity");
    navEntities.forEach((item) => {
      item.style.transform = "rotateX(0deg)";
    });
  }

  const lensesItem = store.SelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.SelectedFocalLength &&
      s.transmission === store.SelectedTransmission
  );
  if (lensesItem) {
    store.setVideoPathOnChange(lensesItem, videoRef);
    singleVideoDetail.value = lensesItem;
    store.generateIcon(lensesItem.icon, iconRef);
  }

  store.scrollToItem(listItem);
  store.generateDropDown(" ", focalLengthRef, transmissionRef);
  store.setSelectedFocalLength(store.SelectedVideoDetails[0].focalLength);
  store.setSelectedTransmission(store.SelectedVideoDetails[0].transmission);
  resetVideoControls();
});

watch(SelectedFocalLength, () => {
  reloadPage();
  store.setSelectedVideoDetails();
  reloadTransmission();
  const lensesItem = store.SelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.SelectedFocalLength &&
      s.transmission === store.SelectedTransmission
  );

  singleVideoDetail.value = lensesItem;
  store.generateIcon(lensesItem.icon, iconRef);
  store.setVideoPathOnChange(lensesItem, videoRef);
  resetVideoControls();
});

watch(SelectedTransmission, () => {
  reloadPage();

  store.setSelectedVideoDetails();

  const lensesItem = store.SelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.SelectedFocalLength &&
      s.transmission === store.SelectedTransmission
  );
  store.generateIcon(lensesItem.icon, iconRef);
  store.setVideoPathOnChange(lensesItem, videoRef);
  singleVideoDetail.value = lensesItem;
  resetVideoControls();
});
</script>

<style scoped>
.video-control-mobile {
  bottom: 15px;
  padding: 0 10px;
  z-index: 3;
  width: 100%;
  flex-direction: column-reverse;
  align-items: end;
  justify-content: space-between;
  gap: 5px;
  min-height: 34px;
}

.video-control-mobile img {
  width: 22px;
  height: 26px;
  padding: 8px 0 4px 8px;
}

.lensesList {
  margin-right: 100px;
}

.fullscreen-img {
  opacity: 0.5;
}

.video-full-screen {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 640px) {
  .lensesList {
    margin-left: 0;
    margin-right: 20px;
  }
}

.lenslist-container {
  display: flex;
  justify-content: center;
}

.video-timeline-bar {
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 10px;
}

.video-timeline-progress {
  position: absolute;
  left: 0;
  width: 0%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pause-button {
  display: block !important;
}

.nav-title {
  margin-top: 0;
}

.mobile-details-container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 400px;
}

.new-badge {
  display: inline-block;
  background-color: #0f52ba;
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  margin-left: 0.5rem;
}
</style>
