<template>
  <AlertBox
    v-if="showComingSoonAlert"
    @closeAlert="showComingSoonAlert = false"
  >
    <div class="premium-upgrade-box">
      <img src="@/assets/img/premium-icon.svg" alt="" />
      <h4 class="gradient-text">Coming Soon</h4>
      <p>We will notify you once the pro version is available.</p>
      <!--<RouterLink class="pricing-link" to="/pricing">View Plans</RouterLink>-->
    </div>
  </AlertBox>
  <div class="dash-tab">
    <div class="plan-box">
      <div class="free-plan-box" v-if="!userStore.userDetails?.userPlan">
        <p>You are currently not subscribed to any of our Premium Plans.</p>
        <span class="gradient-text">
          ( Upgrade to PRO account to unlock more features and access to our
          full PRO library. )
        </span>
        <div class="plan-button">
          <a class="info-link" @click="handlePaymentRouting">View Plans</a>
        </div>
      </div>
      <div class="premium-plan-box" v-else>
        <div class="plan-row">
          <span class="payment-head">Current Plan:</span>
          <span class="payment-detail gradient-text">{{ plan }}</span>
        </div>
        <div class="plan-row">
          <span class="payment-head">Subscription Start Date:</span>
          <span class="payment-detail">{{
            formatDate(userStore.userDetails.userPlan.startDate)
          }}</span>
        </div>
        <div class="plan-row">
          <span class="payment-head">Subscription End Date:</span>
          <span class="payment-detail">{{
            formatDate(userStore.userDetails.userPlan.endDate)
          }}</span>
        </div>
        <div class="plan-row">
          <span class="payment-head">Subscription Status:</span>
          <span
            class="payment-detail badge"
            :class="{
              'badge-green': planStatus === 'active',
              'badge-yellow': planStatus === 'cancelled',
              'badge-red': planStatus === 'expired',
            }"
            >{{ planStatus }}</span
          >
        </div>
        <div class="plan-row plan-status-info">
          (Your plan will be
          {{ planStatus === "active" ? "renewed" : "cancelled" }} on
          {{ formatDate(userStore.userDetails.userPlan.endDate) }}.)
        </div>
        <div class="plan-button">
          <span class="info-link" @click="manageSubscription">{{
            planStatus === "active"
              ? "Manage Subscription"
              : "Renew Subscription"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

import { useUsersStore } from "@/stores/Users";
import handleCheckout from "@/includes/payment";
import { useOverlayStore } from "@/stores/Overlay";
import { formatDate } from "@/includes/helper";
import AlertBox from "../AlertBox.vue";
import { useRouter } from "vue-router";
import config from "@/config/config";

const overlayStore = useOverlayStore();

const router = useRouter();

const userStore = useUsersStore();

const showComingSoonAlert = ref(false);

const handlePaymentRouting = () => {
  if (!config.enablePayment) return (showComingSoonAlert.value = true);

  router.push("/pricing");
};

const manageSubscription = async () => {
  overlayStore.showLoadingOverlay();
  await handleCheckout(
    userStore?.userDetails?.userPlan?.currentPlan.toLowerCase()
  );
  overlayStore.hideLoadingOverlay();
};

const plan = computed(() => {
  if (!userStore?.userDetails?.isPremiumUser) {
    return "Free Plan";
  } else if (userStore?.userDetails.userPlan.currentPlan === "YEARLY") {
    return "Cineflares PRO - Yearly Subscription (Early Adopters)";
  } else {
    return "CINEFLARES PRO - Monthly Subscription";
  }
});

const planStatus = computed(() => {
  if (
    userStore?.userDetails?.userPlan &&
    !userStore?.userDetails.isPremiumUser
  ) {
    return "expired";
  }
  if (userStore?.userDetails.userPlan.active) {
    return "active";
  } else {
    return "cancelled";
  }
});
</script>

<style scoped>
.plan-box {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
}
.payment-head {
  font-weight: 400;
  color: white;
  opacity: 0.7;
  min-width: 200px;
  letter-spacing: 0.5px;
  font-size: 14px;
}
.payment-detail {
  color: white;
  opacity: 0.8;
  letter-spacing: 0.5px;
}

.free-plan-box p {
  font-weight: 400;
  color: white;
  opacity: 0.7;
  margin: 0;
  margin-bottom: 5px;
}

.free-plan-box span {
  font-size: 14px;
}

.info-link {
  padding-inline: 20px;
}
.plan-button {
  margin-top: 30px;
}

.plan-row {
  display: flex;
  margin-bottom: 15px;
}

.badge {
  border-radius: 16px;
  padding: 2px 10px;
  font-size: 12px;
  letter-spacing: 1px;
}

.badge-green {
  background: #a2e2b4;
  color: #077d55;
}

.badge-yellow {
  color: #f5c518;
  background: #faf6cf;
}
.badge-red {
  color: #d91f11;
  background: #fadcd9;
}
.plan-status-info {
  font-size: 12px;
  color: white;
  opacity: 0.4;
  letter-spacing: 0.5px;
}
</style>
