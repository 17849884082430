import { defineStore } from "pinia";
import client from "@/api/client";
export const useNewsStore = defineStore("news", {
  state: () => ({
    base_URL: process.env.VUE_APP_BASE_URL,
    news: [],
  }),
  getters: {},
  actions: {
    async fetchNews() {
      try {
        const API_URL = `${this.base_URL}/news`;
        const response = await client.get(API_URL);

        this.news = response.data || [];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
});
