import { defineStore } from "pinia";
export const useOverlayStore = defineStore("overlay", {
  state: () => ({
    showOverlay: false,
  }),
  getters: {},
  actions: {
    hideLoadingOverlay() {
      this.showOverlay = false;
    },
    showLoadingOverlay() {
      this.showOverlay = true;
    },
  },
});
