<template>
  <div class="pro-yearly pricing-card">
    <div class="pricing-card-relativeElement">
      <div class="pricing-tag">
        <span class="span-pro">PRO</span>
        <span class="pricing-card-underline-element">Yearly</span>
      </div>
      <p class="pro-yearly pricing-card-title">
        Early adopter pricing
        <br />
        <span class="pro-yearly pricing-card-old-price">
          $119. <sup>99</sup>
        </span>
      </p>
    </div>

    <div class="value-tag">Best Value</div>

    <div class="pricing-card-center-div">
      <span class="pricing-card-price">$4<sup>.99</sup></span>
      <span class="pricing-card-center-div-details">
        <span class="pricing-bold">/ month</span>
        <span class="pricing-card-subheading">for 12 month</span>
      </span>
    </div>

    <div>
      <button
        class="pricing-card-subscribe-button"
        @click="handleYearlySubscription"
      >
        {{
          userStore.isLoggedInValue
            ? userStore?.userDetails?.userPlan?.currentPlan === "YEARLY"
              ? "Your Plan"
              : "Subscribe"
            : "Subscribe"
        }}
      </button>
    </div>

    <div class="pricing-card-list-container">
      <div class="pricing-card-list">
        <img src="" alt="" class="pricing-card-tick-image" />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">
            CINEFLARES <span class="pricing-card-subheading">PRO</span>
          </p>
          <span class="cine-info">Professional Lens flares database</span>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">
            Full lens archive access
          </p>
          <span class="pricing-card-subheading"
            >(anamorphic, vintage, modern prime+zoom lenses)</span
          >
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Focal lengths selector</p>
          <span class="pricing-card-subheading">(wide, medium, long lens)</span>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">T-Stop selector</p>
          <span class="pricing-card-subheading"
            >(All T-stops from wide open to T4)</span
          >
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Advanced video player</p>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">
            Free updates of new lenses
          </p>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Advanced lens data</p>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Scene Preview</p>
          <span class="pricing-card-subheading"
            >Compare lenses and see how they would react in different
            environments. (Simulated with optically captured flares and scene
            plates)</span
          >
        </div>
      </div>
    </div>
    <div class="pricing-card-learnMoreContainer">
      <p class="pricing-card-inline-element">
        $59.<sup> 88 </sup>
        <span class="pricing-card-underline-element">billed yearly</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import handleCheckout from "@/includes/payment";
import { useOverlayStore } from "@/stores/Overlay";
import { useUsersStore } from "@/stores/Users";
import { useRouter } from "vue-router";
const router = useRouter();

const userStore = useUsersStore();
const overlayStore = useOverlayStore();

const handleYearlySubscription = async () => {
  if (!userStore.isLoggedInValue) return router.push("/login");

  overlayStore.showLoadingOverlay();
  await handleCheckout("yearly");
  overlayStore.hideLoadingOverlay();
};
</script>

<style scoped>
.pricingContainer {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow-y: scroll;
}
.pricing-card-old-price {
  position: relative;
  display: inline-block;
}
.pricing-card-old-price::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #bbe3e9, #a376ae);
  transform: rotate(-15deg);
}
.pricing-card-old-price {
  font-size: 14px;
  background: linear-gradient(to right, #bbe3e9, #a376ae);
  -webkit-background-clip: text;
  color: transparent;
}

.pricing-card-title {
  background: linear-gradient(to right, #bbe3e9, #a376ae);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
  margin: 0;
  font-size: 14px;
}
.pricing-card-bestValue-div {
  background-color: #9218b9;
  padding: 3px;
  margin: 0 !important;
}
.pricing-tag {
  display: flex;
  flex-direction: column;
}
.span-pro {
  height: fit-content;
}
.pro {
  font-size: 17px;
}
h1 {
  text-align: center;
}

.pricing-card-inline-element {
  display: inline;
}

.pricing-card-relativeElement {
  position: relative;
  display: flex;
  gap: 5px;
}

.span {
  font-size: 10px;
}

.pricing-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 3%;
  width: 70vw;
  margin-top: 0;
}

.pricing-title {
  text-align: center;
  position: relative;
  margin-top: 7vh;
  font-size: 27px;
  margin-bottom: 0px;
}

.value-tag {
  position: absolute;
  top: 30px;
  right: 0;
  background: #9218b9;
  padding: 4px 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
}

.pro-yearly.pricing-card {
  border: 1px solid rgba(255, 255, 255, 0.568);
}
</style>
