import client from "./client";

export const getSampleImages = async (lensId) => {
  try {
    const response = await client.get(`/sample-image/lens/${lensId}`);
    return response;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};
