<template>
  <div class="faq-item">
    <div class="faq-question" @click="toggle" :class="{ 'tab-open': isOpen }">
      <h3>{{ question }}</h3>
      <img src="@/assets/img/plus-sign.svg" class="tab-action" alt="" />
    </div>
    <transition name="expand">
      <div v-if="isOpen" class="faq-answer" v-html="answer"></div>
    </transition>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

defineProps({
  question: String,
  answer: String,
});

const isOpen = ref(false);

const toggle = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style scoped>
.faq-item {
  width: 100%;
  /* background: white; */
}

.faq-item:not(:last-child) {
  border-bottom: 1px solid rgb(234, 234, 234, 0.5);
}

.faq-question {
  cursor: pointer;
  color: #fff;
  /* background: #fafafa; */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.faq-question h3 {
  margin: 0;
  font-weight: 400;
}

.faq-answer {
  padding: 20px;
  color: #fff;
  /* background: white; */

  transform-origin: top;
  line-height: 140%;
}

.tab-open {
  border-bottom: 1px solid rgb(234, 234, 234, 0.5);
}

.tab-action {
  width: 15px;
  transition: all 0.3s;
}

.tab-open .tab-action {
  transform: rotate(45deg);
}
</style>
