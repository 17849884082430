import client from "@/api/client";

const handleCheckout = async (plan) => {
  try {
    const response = await client.post(
      `${process.env.VUE_APP_BASE_URL}/checkout/create-checkout-session`,
      { plan }
    );

    if (response.data.url) {
      window.location = response.data.url;
    }
  } catch (err) {
    console.log(err);
  }
};

export default handleCheckout;
