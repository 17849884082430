<template>
  <div class="search-box">
    <div class="search-input-box">
      <div class="search-icon">
        <img src="@/assets/img/search_icon.png" alt="" />
      </div>
      <input
        type="text"
        class="search-input"
        :value="searchKeyword"
        @input="(evt) => (searchKeyword = evt.target.value)"
        @blur="handleSearchBlur"
        @focus="handleSearchFocus"
      />
    </div>
    <div class="search-result-box" v-if="showSearchResults">
      <div class="search-results">
        <div v-if="loading" class="loading-div">
          <l-line-spinner
            size="20"
            stroke="2"
            speed="1"
            color="rgba(255,255,255,0.5)"
          ></l-line-spinner>
        </div>
        <div v-else>
          <div v-if="searchKeyword === '' && searchData.length === 0">
            Enter your keyword
          </div>
          <div v-if="searchData.length > 0">
            <div
              class="search-result"
              v-for="search in searchData"
              :key="search"
            >
              <div class="search-head">{{ search.categoryName }}</div>
              <div
                class="search-data"
                v-for="lenses in search.filteredLenses"
                :key="lenses.name"
                :class="{ 'search-scene-data': isScene }"
                @click="handleSearchDataClick(lenses.name)"
              >
                <span>{{ lenses.name }}</span>
                <span
                  v-if="lenses.isNew"
                  class="new-badge"
                  style="font-size: 10px"
                  >new</span
                >
              </div>
            </div>
          </div>
          <div v-if="searchKeyword !== '' && searchData.length === 0">
            No results found.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";

const props = defineProps(["isScene"]);

import { useLensesStore } from "@/stores/Lenses";
import { useScenesStore } from "@/stores/Scenes";
import { useRouter } from "vue-router";
import { lineSpinner } from "ldrs";
import client from "@/api/client";
import { ampli } from "@/ampli";

lineSpinner.register();
const loading = ref(false);

const router = useRouter();

const lensStore = useLensesStore();
const sceneStore = useScenesStore();

const searchData = ref([]);
const debounceTimer = ref(null);

const searchKeyword = ref("");
const showSearchResults = ref(false);

let currentPathObject = router.currentRoute.value;

const handleSearchFocus = () => {
  showSearchResults.value = true;
  if (props.isScene) {
    sceneStore.searchIsActive = true;
  } else {
    lensStore.searchIsActive = true;
  }
};
const handleSearchBlur = () => {
  setTimeout(() => {
    searchKeyword.value = "";
    searchData.value = [];

    showSearchResults.value = false;
    if (props.isScene) {
      sceneStore.searchIsActive = false;
    } else {
      lensStore.searchIsActive = false;
    }
  }, 200);
};
const handleSearchDataClick = (name) => {
  if (props.isScene) {
    sceneStore.setSelectedVideo(name.replace(/\s+/g, "-").toLowerCase());
  } else {
    lensStore.setSelectedVideo(name.toLowerCase().replace(/\s+/g, "-"));
  }

  searchKeyword.value = "";
};
const removeDuplicates = (data) => {
  let lensDict = {};

  // Iterate through the array
  data.forEach((lens) => {
    if (!lensDict[lens.name]) {
      // If lens name not in dict, add it
      lensDict[lens.name] = { name: lens.name, isNew: lens.isNew };
    } else {
      // If lens name is already in dict, update isNew state
      if (lens.isNew) {
        lensDict[lens.name].isNew = true;
      }
    }
  });

  // Convert the dictionary back to an array
  let uniqueLenses = Object.values(lensDict);

  return uniqueLenses;
};
const searchLens = async () => {
  const endPoint = props.isScene
    ? `/lens/scenes/search?sceneType=${sceneStore.sceneType.toUpperCase()}&searchQuery=`
    : "/lens/search?query=";
  try {
    /*
      AMPLITUDE: Track lens search
      Triggers when a lens search API is called
    */
    ampli.lensSearched({
      query: searchKeyword?.value,
      page: currentPathObject?.fullPath,
    });

    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}${endPoint}${searchKeyword.value}`
    );

    if (response.data.code === "auth/id-token-expired") {
      router.push("/login");
      return;
    }

    let unfilteredArray = [];

    unfilteredArray = props.isScene ? response.data : response.data.lens;
    let filteredArray = [];

    unfilteredArray.forEach((array) => {
      const lensObject = array.lenses?.map((lens) => {
        return { name: lens.name, isNew: lens.isNew };
      });
      const filteredLenses = removeDuplicates(lensObject);

      filteredArray.push({
        categoryName: array.name,
        filteredLenses,
      });
    });

    searchData.value = filteredArray;
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

watch(searchKeyword, async () => {
  if (!searchKeyword.value) {
    searchData.value = [];
    clearTimeout(debounceTimer.value);
    return;
  }

  if (debounceTimer.value) clearTimeout(debounceTimer.value);

  loading.value = true;

  debounceTimer.value = setTimeout(() => {
    searchLens();
  }, 500);
});
</script>

<style scoped>
.search-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
  position: relative;
}
.search-input-box {
  width: 100%;
  position: relative;
  max-width: 300px;
  margin: 0 20px;
}

.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.search-icon img {
  width: 22px;
}

.search-input {
  width: 100%;
  background: rgba(51, 152, 219, 0.2);
  padding: 12px 60px 12px 30px;
  color: white;
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 16px;
}

.search-result-box {
  max-height: 400px;
  overflow-y: auto;
  max-width: 300px;
  margin-right: 0;
}

.empty-search-placehold {
  padding: 5px;
  color: rgb(207, 207, 207);
  font-size: 16px;
  letter-spacing: 0.5px;
}

.new-badge {
  height: fit-content;
}
</style>
