<template>
  <div class="browse-popup-container" v-if="lensDetails">
    <img
      src="@/assets/img/crossmark.svg"
      class="select-close"
      @click="emits('closePopup')"
    />
    <div class="site-logo">
      <img :src="userStore.logoSource" alt="cineflares" />
    </div>
    <div class="select-video-container">
      <div class="video-box">
        <div
          v-if="showLoading"
          class="spinner-container-right"
          id="spinner-right"
          ref="spinnerRightRef"
        >
          <div class="spinner-load"></div>
        </div>
        <video
          @click="toggleVideoStatus"
          ref="selectVideoRef"
          class="video"
          preload="auto"
          @loadedmetadata="canplaythrough"
          @ended="reloadVideo"
          disablepictureinpicture
          oncontextmenu="return false;"
          :poster="lensDetails.flareImage || lensDetails.preview"
          controlslist="nodownload noremoteplayback noplaybackrate"
          @pause="videoPlaying = false"
          @play="videoPlaying = true"
          playsinline
        >
          <source :src="lensDetails.videoPath" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div class="video-controls video-controls-right">
          <div
            class="video-control-option video-full-screen"
            id="fullScreenRight"
            @click="enterFullScreen"
          >
            <img src="@/assets/img/fullscreen.svg" width="12" />
          </div>
        </div>
        <div
          class="play-button-img"
          id="playButton"
          ref="playButton"
          @click="playVideo()"
          v-if="!videoPlaying && !showLoading"
        >
          <img src="@/assets/img/play.png" />
        </div>
      </div>
      <!-- <div class="select-actions" data-html2canvas-ignore="true">
                <div class="select-action" @click="exportToPDF">
                  Download Specifications
                  <img src="@/assets/img/download.svg" alt="" />
                </div>
                <div class="select-action">
                  Share Specifications
                  <img src="@/assets/img/share.svg" alt="" />
                </div>
              </div> -->
    </div>

    <div class="select-video-details">
      <div class="select-lens-icon">
        <img src="@/assets/img/lens-icon.svg" alt="" />
      </div>
      <div class="select-detail-contents">
        <div class="select-head" @click="showDetails = !showDetails">
          <h3>{{ lensDetails.name }}</h3>
          <span>{{ lensDetails.focalLength }}</span>
          <span>{{ lensDetails.transmission }}</span>
          <img :src="lensDetails.icon" alt="" class="select-head-lens-icon" />
          <span class="details-category">{{
            lensDetails.categoryName || "N/A"
          }}</span>
        </div>
        <Transition name="expand">
          <div class="lens-details-box" v-if="showDetails" id="test">
            <div class="lens-details">
              <div class="details-container">
                <div class="details-top-row">
                  <div class="details-row">
                    <div class="details-col">
                      <div class="detail-head">Focal Length:</div>
                      <div class="detail-value">
                        {{ lensDetails.focalLength || "N/A" }}
                      </div>
                    </div>
                    <div class="details-col">
                      <div class="detail-head">Transmission:</div>
                      <div class="detail-value">
                        {{ lensDetails.transmission || "N/A" }}
                      </div>
                    </div>
                  </div>
                  <div class="details-row">
                    <div class="details-col">
                      <div class="detail-head">Manufacturer:</div>
                      <div class="detail-value">
                        {{ lensDetails.manufacturer || "N/A" }}
                      </div>
                    </div>
                    <div class="details-col">
                      <div class="detail-head cliphead">Series:</div>
                      <div class="value-box-w-tooltip">
                        <div class="clipped-tooltip" v-if="lensDetails.series">
                          {{ lensDetails.series }}
                        </div>
                        <div class="detail-value clip">
                          {{ lensDetails.series || "N/A" }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details-row">
                    <div class="details-col">
                      <div class="detail-head">Lens Type:</div>
                      <div class="detail-value">
                        {{ lensDetails.lensType || "N/A" }}
                      </div>
                    </div>
                    <div class="details-col">
                      <div class="detail-head">Lens Format:</div>
                      <div class="detail-value">
                        {{ lensDetails.lensFormat || "N/A" }}
                      </div>
                    </div>
                  </div>
                  <div class="details-row">
                    <div class="details-col">
                      <div class="detail-head">Image Circle:</div>
                      <div class="detail-value">
                        {{ lensDetails.imageCircle || "N/A" }}
                      </div>
                    </div>
                    <div class="details-col">
                      <div class="detail-head">Aperture Range:</div>
                      <div class="detail-value">
                        {{ lensDetails.apertureRange || "N/A" }}
                      </div>
                    </div>
                  </div>
                  <div class="details-row">
                    <div class="details-col">
                      <div class="detail-head">Distortion:</div>
                      <div class="detail-value">
                        {{ lensDetails.distortion || "N/A" }}
                      </div>
                    </div>
                    <div class="details-col">
                      <div class="detail-head">Weight:</div>
                      <div class="detail-value">
                        {{ lensDetails.weight || "N/A" }}
                      </div>
                    </div>
                  </div>

                  <div class="details-row">
                    <div class="details-col">
                      <div class="detail-head">Squeeze Factor:</div>
                      <div class="detail-value">
                        {{
                          lensDetails.squeezeFactor
                            ? lensDetails.squeezeFactor
                            : "N/A"
                        }}
                      </div>
                    </div>

                    <div class="details-col">
                      <div class="detail-head">Year:</div>
                      <div class="detail-value">
                        {{ lensDetails.year ? lensDetails.year : "N/A" }}
                      </div>
                    </div>
                  </div>

                  <div class="details-row">
                    <div class="details-col">
                      <div class="detail-head">Country of Origin:</div>
                      <div class="detail-value">
                        {{
                          lensDetails.countryOfOrigin
                            ? lensDetails.countryOfOrigin
                            : "N/A"
                        }}
                      </div>
                    </div>

                    <!-- Empty col to hold the layout if only one colum is required-->
                    <div class="details-col">
                      <div class="detail-head">Close Focus:</div>
                      <div class="detail-value">
                        {{
                          lensDetails.closeFocus
                            ? lensDetails.closeFocus
                            : "N/A"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="details-bottom-row">
                  <div class="details-bottom-left">
                    <div class="details-row">
                      <div class="details-col">
                        <div class="detail-head">Flare Type:</div>
                        <div class="detail-value tag">
                          {{
                            getLensPropOptionLabel(
                              "flareType",
                              lensDetails.flareType
                            ) || "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="details-row">
                      <div class="details-col">
                        <div class="detail-head">Flare Color:</div>
                        <div class="detail-value tag">
                          {{
                            getLensPropOptionLabel(
                              "flareColor",
                              lensDetails.flareColor
                            ) || "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="details-row">
                      <div class="details-col">
                        <div class="detail-head">Flare Intensity:</div>
                        <div class="detail-value tag">
                          {{
                            getLensPropOptionLabel(
                              "flareIntensity",
                              lensDetails.flareIntensity
                            ) || "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="details-row">
                      <div class="details-col">
                        <div class="detail-head">Lens Era:</div>
                        <div class="detail-value tag">
                          {{
                            getLensPropOptionLabel(
                              "lensEra",
                              lensDetails.lensEra
                            ) || "N/A"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details-bottom-right">
                    <div
                      class="films-row"
                      v-if="lensDetails.notableFilms?.length > 0"
                    >
                      <div class="films-head">Notable Films:</div>
                      <div class="films-data-box">
                        <div
                          class="films-data detail-value"
                          v-for="film in lensDetails.notableFilms"
                          :key="film.link"
                        >
                          <a :href="film.link" target="_blank"
                            >{{ film.name }}
                            <img src="@/assets/img/link-icon.svg" alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focal-length-details">
              <div class="table-wrapper">
                <table class="custom-table">
                  <thead>
                    <tr>
                      <th>Focal Length</th>
                      <th>Max Aperture</th>
                      <th>Close Focus</th>
                      <th>Weight</th>
                      <th>Length</th>
                      <th>Image Circle</th>
                      <th>Front Diameter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ lensDetails.focalLength || "N/A" }}
                      </td>
                      <td>
                        {{ lensDetails.apertureRange || "N/A" }}
                      </td>
                      <td>
                        {{ lensDetails.closeFocus || "N/A" }}
                      </td>
                      <td>
                        {{ lensDetails.weight || "N/A" }}
                      </td>
                      <td>
                        {{ lensDetails.length || "N/A" }}
                      </td>
                      <td>
                        {{ lensDetails.imageCircle || "N/A" }}
                      </td>
                      <td>
                        {{ lensDetails.frontDiameter || "N/A" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="sample-images-box" v-if="sampleImages.length > 0">
              <SampleImages
                :images="sampleImages"
                :lensName="lensDetails.name"
              />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useLensesStore } from "@/stores/Lenses";
import { useUsersStore } from "@/stores/Users";
import { onMounted, onUnmounted, ref, defineEmits } from "vue";
//import SelectPDF from "@/components/Selects/SelectPDF.vue";
// import savePdf from "@/includes/savePdf";
// import { useOverlayStore } from "@/stores/Overlay";
import { getLensPropOptionLabel } from "@/utils/string";
import { defineProps } from "vue";
import SampleImages from "../SampleImages.vue";
import { getSampleImages } from "@/api/sampleImages";

const props = defineProps(["lensDetails"]);
const emits = defineEmits(["closePopup"]);

const selectVideoRef = ref(null);
const showLoading = ref(true);
const videoPlaying = ref(false);
const showDetails = ref(true);
const sampleImages = ref([]);

// const pdfRef = ref(null);

const lensStore = useLensesStore();
const userStore = useUsersStore();

// const overlayStore = useOverlayStore();

//const exportToPDF = async () => {
//  overlayStore.showLoadingOverlay();
//
//  await savePdf(pdfRef.value, lensDetails);
//  overlayStore.hideLoadingOverlay();
//};

const canplaythrough = () => {
  showLoading.value = false;
};

const playVideo = () => {
  selectVideoRef.value.play();
};

const reloadVideo = () => {
  selectVideoRef.value.load();
};

const toggleVideoStatus = () => {
  selectVideoRef.value.pause();
};

const enterFullScreen = () => {
  lensStore.enterFullscreen(selectVideoRef);
};

function handleKeyEvents(e) {
  e.preventDefault();

  const video = selectVideoRef.value;
  const playBackSpeed = 1;

  if (e.key === " ") {
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  } else if (e.key === "ArrowRight") {
    if (video.currentTime > 0) {
      video.currentTime += playBackSpeed;
    }
  } else if (e.key === "ArrowLeft") {
    if (video.currentTime > 0) {
      video.currentTime -= playBackSpeed;
    }
  }
}

const fetchSampleImages = async () => {
  const response = await getSampleImages(props.lensDetails.genericId);

  if (response.data) {
    sampleImages.value = response.data.images;
  }

  // Specific lens sample images
  const specificSampleImages = props.lensDetails?.sampleImages;

  if (specificSampleImages) {
    sampleImages.value = [...sampleImages.value, ...specificSampleImages];
  }
};

onMounted(async () => {
  await fetchSampleImages();
  window.addEventListener("keydown", handleKeyEvents);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyEvents);
});
</script>

<style scoped>
.video {
  display: block;
}

.select-actions {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 10px;
  gap: 7px;
}

.select-action {
  color: white;
  opacity: 0.7;
  font-size: 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.select-video-details {
  display: flex;
  gap: 15px;
}

.select-action img {
  width: 13px;
}

.select-lens-icon {
  width: 30px;
}

.select-lens-icon img {
  width: 30px;
  margin-top: 10px;
}
.select-head {
  color: white;
  gap: 5px;
  opacity: 0.8;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.select-head h3 {
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 25px;
}

.select-head span:not(:nth-last-child(2)) {
  margin-right: 25px;
}

.select-head-lens-icon {
  height: 50px;
}

.select-detail-contents {
  font-size: 12px;
  color: #4a4c4d;
  font-weight: bolder;
  letter-spacing: 1px;
  font-weight: bold;
  flex: 1;
}

.lens-details-box {
  display: flex;
  gap: 40px;
  flex-direction: column;
  transform-origin: top;
}

.lens-details {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.select-details-col {
  margin-bottom: 10px;
  display: flex;
}

.select-details-col .detail-head {
  min-width: 140px;
}

.custom-table {
  border-spacing: 0;
  width: 100%;
}

.custom-table th,
.custom-table td {
  text-align: left;
  padding-bottom: 10px;
  min-width: 100px;
}

.custom-table th {
  color: #d4d4d3;
  opacity: 0.7;
  font-weight: 400;
}

.right-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.middle {
  padding: 40px 60px;
  background: #1a1e1e;

  flex: 2;
  display: block;
}

.focal-length-details {
  overflow: hidden;
}

.table-wrapper {
  max-width: calc(100vw - 100px);
  overflow-y: auto;
  padding-bottom: 10px;
}

.site-logo {
  padding-bottom: 20px;
}

.table-wrapper::-webkit-scrollbar {
  display: block !important;
}

.details-category {
  margin-bottom: 0;
}

.select-middle {
  position: relative;
}

.select-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  opacity: 0.5;
  filter: invert(100%) sepia(14%) saturate(5106%) hue-rotate(227deg)
    brightness(112%) contrast(111%);
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .middle {
    flex: 3;
  }
}
@media screen and (max-width: 1400px) {
  .middle {
    flex: 4;
  }
}
@media screen and (max-width: 1200px) {
  .middle {
    flex: 6;
  }
}

@media screen and (max-width: 1024px) {
  .select-middle {
    padding-top: 20px;
  }

  .middle {
    padding: 20px;
  }

  .secondary-body {
    padding-top: 30px;
  }

  .select-video-details {
    margin-top: 30px;
  }

  .select-actions {
    align-items: flex-start;
  }

  .details-row,
  .details-bottom-row {
    flex-direction: column;
  }
  .films-row {
    justify-content: start;
  }
  .details-bottom-right {
    align-self: start;
  }

  .select-head {
    flex-wrap: wrap;
  }

  .select-lens-icon {
    display: none;
  }

  .select-head[data-v-3fb73828] {
    font-size: 12px;
    gap: 10px;
  }

  .select-head-lens-icon {
    height: 30px;
  }

  .select-head span:not(:nth-last-child(2)) {
    margin-right: 0px;
  }

  .select-head h3 {
    margin-right: 10px;
  }

  .select-middle {
    padding-top: 50px;
  }
}

.lens-details {
  display: block;
  font-weight: 400;
}

.detail-value {
  color: #fff;
  opacity: 0.7;
}

.video-box {
  margin-bottom: 20px;
}

.browse-popup-container {
  padding: 20px;
}
</style>
