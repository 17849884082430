<template>
  <SceneDesktop v-if="!screenStore.isMobile" />
  <SceneMobile v-else></SceneMobile>
</template>

<script setup>
import SceneDesktop from "@/components/Scene/SceneDesktop";
import SceneMobile from "@/components/Scene/SceneMobile";
import { useScreenStore } from "@/stores/Screen";

const screenStore = useScreenStore();
</script>
