import { auth } from '@/firebase/init';
import axios from 'axios';

const client = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

// Request interceptor to add the Authorization header
client.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default client;
