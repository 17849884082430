<template>
  <div class="details-container-box">
    <div class="details-container-title" @click="$emit('showDetails')">
      <h4 class="details-inline-element">{{ videoDetails.name }}</h4>
      <span class="details-category">{{ videoDetails.lensCategoryName }}</span>
    </div>
    <Transition name="expand">
      <div
        class="details-container"
        v-if="showLensDetails && lensStore.isPremiumUser"
      >
        <div class="details-top-row">
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Focal Length:</div>
              <div class="detail-value">
                {{
                  videoDetails.focalLength ? videoDetails.focalLength : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Transmission:</div>
              <div class="detail-value">
                {{
                  videoDetails.transmission ? videoDetails.transmission : "N/A"
                }}
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Manufacturer:</div>
              <div class="detail-value">
                {{
                  videoDetails.manufacturer ? videoDetails.manufacturer : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head cliphead">Series:</div>
              <div class="value-box-w-tooltip">
                <div class="clipped-tooltip" v-if="videoDetails.series">
                  {{ videoDetails.series }}
                </div>
                <div class="detail-value clip">
                  {{ videoDetails.series ? videoDetails.series : "N/A" }}
                </div>
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Lens Type:</div>
              <div class="detail-value">
                {{ videoDetails.lensType ? videoDetails.lensType : "N/A" }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Lens Format:</div>
              <div class="detail-value">
                {{ videoDetails.lensFormat ? videoDetails.lensFormat : "N/A" }}
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Image Circle:</div>
              <div class="detail-value">
                {{
                  videoDetails.imageCircle ? videoDetails.imageCircle : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Aperture Range:</div>
              <div class="detail-value">
                {{
                  videoDetails.apertureRange
                    ? videoDetails.apertureRange
                    : "N/A"
                }}
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Distortion:</div>
              <div class="detail-value">
                {{ videoDetails.distortion ? videoDetails.distortion : "N/A" }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Weight:</div>
              <div class="detail-value">
                {{ videoDetails.weight ? videoDetails.weight : "N/A" }}
              </div>
            </div>
          </div>

          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Squeeze Factor:</div>
              <div class="detail-value">
                {{
                  videoDetails.squeezeFactor
                    ? videoDetails.squeezeFactor
                    : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Year:</div>
              <div class="detail-value">
                {{ videoDetails.year ? videoDetails.year : "N/A" }}
              </div>
            </div>
          </div>

          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Country of Origin:</div>
              <div class="detail-value">
                {{
                  videoDetails.countryOfOrigin
                    ? videoDetails.countryOfOrigin
                    : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Close Focus:</div>
              <div class="detail-value">
                {{ videoDetails.closeFocus ? videoDetails.closeFocus : "N/A" }}
              </div>
            </div>
          </div>
        </div>

        <div class="details-bottom-row">
          <div class="details-bottom-left">
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Flare Type:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel(
                      "flareType",
                      videoDetails?.flareType
                    ) || "N/A"
                  }}
                </div>
              </div>
            </div>
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Flare Color:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel(
                      "flareColor",
                      videoDetails?.flareColor
                    ) || "N/A"
                  }}
                </div>
              </div>
            </div>
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Flare Intensity:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel(
                      "flareIntensity",
                      videoDetails?.flareIntensity
                    ) || "N/A"
                  }}
                </div>
              </div>
            </div>
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Lens Era:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel("lensEra", videoDetails?.lensEra) ||
                    "N/A"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="details-bottom-right">
            <div class="films-row" v-if="videoDetails.notableFilms?.length > 0">
              <div class="films-head">Notable Films:</div>
              <div class="films-data-box">
                <div
                  class="films-data detail-value"
                  v-for="film in videoDetails.notableFilms"
                  :key="film.link"
                >
                  <a :href="film.link" target="_blank"
                    >{{ film.name }}
                    <img src="@/assets/img/link-icon.svg" alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flares-sample" v-if="sampleImages.length > 0">
          <SampleImages :images="sampleImages" :lensName="videoDetails.name" />
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import { getSampleImages } from "@/api/sampleImages";
import { useLensesStore } from "@/stores/Lenses";
import { defineProps, onMounted, ref, watch } from "vue";
import SampleImages from "./SampleImages.vue";
import { getLensPropOptionLabel } from "@/utils/string";
const sampleImages = ref([]);

const lensStore = useLensesStore();

const props = defineProps({
  videoDetails: Object,
  side: String,
  showLensDetails: Boolean,
});

const fetchSampleImages = async () => {
  sampleImages.value = [];
  const response = await getSampleImages(props.videoDetails.genericId);
  if (response.data) {
    sampleImages.value = response.data.images;
  }

  // Specific lens sample images
  const specificSampleImages = props.videoDetails?.sampleImages;

  if (specificSampleImages) {
    sampleImages.value = [...sampleImages.value, ...specificSampleImages];
  }
};

onMounted(async () => {
  await fetchSampleImages();
});

watch(
  () => props.videoDetails.genericId,
  async () => {
    await fetchSampleImages();
  }
);
</script>
<style scoped>
.left-column {
  flex: 1;
  margin: 4px;
}

@media screen and (max-width: 1024px) {
  .details-row {
    flex-direction: column;
  }

  .detail-value.clip {
    max-width: none;
  }

  .details-bottom-row {
    flex-direction: column;
  }
  .films-row {
    justify-content: flex-start;
  }

  .details-bottom-right {
    align-self: flex-start;
  }

  .details-container-title {
    font-weight: bold;
    color: white;
    font-size: 14px;
  }
  .details-category {
    font-size: 10px;
    text-align: center;
    line-height: 16px;
  }
}

.flares-sample {
  margin-top: 16px;
  border-top: 1px solid #4a4c4d;
  padding-block: 10px;
}
</style>
