<template>
  <div class="free pricing-card pricing-card">
    <p class="pricing-card-title">Free</p>

    <div class="pricing-card-center-div">
      <span class="pricing-card-price">$0</span>
      <span style="margin: 7px">
        <span style="display: block"> forever</span>
      </span>
    </div>

    <div>
      <button
        class="pricing-card-join-button"
        :data="
          userStore.isLoggedInValue
            ? userStore?.userDetails?.isPremiumUser
              ? 'Subscribe'
              : 'Your Plan'
            : 'Join For Free'
        "
        @click="handleSubsciptionClick"
      >
        Placeholder Do not remove
      </button>
    </div>

    <div class="pricing-card-list-container">
      <div class="pricing-card-list">
        <img src="" alt="" class="pricing-card-tick-image" />
        <p class="pricing-card-listElement">Try out CINEFLARES for free</p>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Basic lens library</p>
          <span class="pricing-card-subheading">(15 lenses)</span>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Basic video player</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import handleCheckout from "@/includes/payment";
import { useOverlayStore } from "@/stores/Overlay";
import { useUsersStore } from "@/stores/Users";
import { useRouter } from "vue-router";

const overlayStore = useOverlayStore();

const router = useRouter();

const userStore = useUsersStore();

const handleSubsciptionClick = async () => {
  if (!userStore.isLoggedInValue) return router.push("/signup");

  if (!userStore.userDetails?.isPremiumUser) return;

  overlayStore.showLoadingOverlay();
  await handleCheckout("yearly");
  overlayStore.hideLoadingOverlay();
};
</script>

<style scoped>
.pricing-card-learnMore-button {
  background-color: transparent;
  color: white;
  padding: 8px;
  margin: 7px;
  border: 2px solid #07101c;
  border-radius: 5px;
}
.pricing-card-learnMore-container {
  margin-left: 15px;
  margin-top: 1rem;
}

.pricing-card-join-button {
  background: linear-gradient(
    90deg,
    rgba(49, 149, 212, 1) 0%,
    rgba(159, 156, 211, 1) 50%,
    rgba(177, 187, 130, 1) 100%
  );
  color: white;
  padding: 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  margin-top: 15px;
}

.pricing-card-join-button::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background-color: #102034;
  border-radius: 4px;
}

.pricing-card-join-button::after {
  position: absolute;
  content: attr(data);
  font-size: 16px;
  width: 100%;
  inset: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.pricing-card-underline-element {
  text-decoration: underline;
}

.pricing-card-inline-element {
  display: inline;
}

.pricing-card-relativeElement {
  position: relative;
}

.pricing-card-title {
  background: linear-gradient(to right, #bbe3e9, #a376ae);
  -webkit-background-clip: text;
  color: transparent;
  max-width: 35%;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 14px;
}
.pricing-card-center-div {
  gap: 0;
}
</style>
