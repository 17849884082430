<template>
  <div class="pro-monthly pricing-card">
    <div class="pricing-card-relativeElement">
      <p class="pricing-card-inline-element">CINEFLARES</p>
      <span class="span-pro">PRO</span>
    </div>

    <div>
      <span class="pricing-card-underline-element">1 month</span>
    </div>

    <div class="pricing-card-center-div">
      <span class="pricing-card-price">$29<sup>.99</sup></span>
      <span class="pricing-card-center-div-details">
        <span class="pricing-bold">/ month</span>
        <span class="pricing-card-subheading">Cancel Anytime</span>
      </span>
    </div>

    <button
      class="pricing-card-subscribe-button"
      @click="handleMonthlySubscription"
    >
      {{
        userStore.isLoggedInValue
          ? userStore?.userDetails?.userPlan?.currentPlan === "MONTHLY"
            ? "Your Plan"
            : "Subscribe"
          : "Subscribe"
      }}
    </button>

    <div class="pricing-card-list-container">
      <div class="pricing-card-list">
        <img src="" alt="" class="pricing-card-tick-image" />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">
            CINEFLARES <span class="pricing-card-subheading">PRO</span>
          </p>
          <span class="cine-info">Professional Lens Flares Database</span>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">
            Full lens archive access
          </p>
          <span class="pricing-card-subheading"
            >(anamorphic, vintage, modern prime+zoom lenses)</span
          >
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Focal lengths selector</p>
          <span class="pricing-card-subheading">(wide, medium, long lens)</span>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">T-Stop selector</p>
          <span class="pricing-card-subheading"
            >(All T-stops from wide open to T4)</span
          >
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Advanced video player</p>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">
            Free updates of new lenses
          </p>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Advanced lens data</p>
        </div>
      </div>

      <div class="pricing-card-list">
        <img
          src="../../assets/img/pricing-tick.svg"
          alt=""
          class="pricing-card-tick-image"
        />
        <div class="pricing-card-divElement">
          <p class="pricing-card-divElement-heading">Scene Preview</p>
          <span class="pricing-card-subheading"
            >Compare lenses and see how they would react in different
            environments. (Simulated with optically captured flares and scene
            plates)</span
          >
        </div>
      </div>
    </div>
    <div class="pricing-card-learnMoreContainer">
      <p class="pricing-card-inline-element">
        $29.<sup> 99 </sup>
        <span class="pricing-card-underline-element">billed monthly</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import handleCheckout from "@/includes/payment";
import { useOverlayStore } from "@/stores/Overlay";
import { useUsersStore } from "@/stores/Users";
import { useRouter } from "vue-router";

const userStore = useUsersStore();
const overlayStore = useOverlayStore();
const router = useRouter();

const handleMonthlySubscription = async () => {
  if (!userStore.isLoggedInValue) return router.push("/login");

  overlayStore.showLoadingOverlay();
  await handleCheckout("monthly");
  overlayStore.hideLoadingOverlay();
};
</script>

<style scoped>
.pricingContainer {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow-y: scroll;
}
.pro {
  font-size: 17px;
}
h1 {
  text-align: center;
}

.pricing-card-inline-element {
  display: inline;
  margin-right: 5px;
}

.pricing-card-relativeElement {
  position: relative;
}

.span {
  font-size: 10px;
}

.pricing-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 3%;
  width: 70vw;
  margin-top: 0;
}
</style>
