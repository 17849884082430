<template>
  <div class="column middle">
    <div class="sub-column">
      <div class="inline-div-left">
        <label
          class="video-label-active"
          id="video-label-left"
          ref="videoLabelLeft"
        ></label>
        <select
          ref="focalLengthLeft"
          id="focalLengthLeft"
          name="focalLengthLeft"
          style="display: none"
          @change="setFocalLengthChange($event, 'LEFT')"
        >
          <option
            v-for="option in store.focalLengthOptionsLeft"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <select
          ref="transmissionLeft"
          id="transmissionLeft"
          name="transmissionLeft"
          style="display: none"
          @change="setTransmission($event, 'LEFT')"
        >
          <option
            v-for="option in store.transmissionsLeft"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <img id="iconLeft" style="display: none" ref="iconLeft" />
      </div>
      <div class="video-box">
        <SaveBtn
          :status="leftSaveBtnStatus"
          @click="addWishlist('LEFT')"
          :loading="leftSaveBtnLoading"
          :viewCondition="store.pauseVideo"
        />
        <div class="spinner-container" id="spinner-left" ref="spinnerLeftRef">
          <div class="spinner-load"></div>
        </div>
        <video
          id="video1"
          ref="video1Ref"
          class="video"
          preload="auto"
          @waiting="syncVideos('video1')"
          @canplaythrough="CanPlayThrough('video1')"
          disablepictureinpicture
          oncontextmenu="return false;"
          controlslist="nodownload noremoteplayback noplaybackrate"
          @pause="store.setPauseVideo()"
          @play="store.setPlayVideo()"
        >
          <source src="" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div class="video-controls video-controls-left">
          <div
            class="video-control-option video-full-screen"
            id="fullScreenLeft"
            @click="enterFullscreen('LEFT')"
          >
            <img src="../assets/img/fullscreen.svg" width="12" />
          </div>
        </div>
      </div>
    </div>
    <div class="sub-column">
      <div class="inline-div-right">
        <img id="iconRight" style="display: none" ref="iconRight" />
        <label
          class="video-label"
          id="video-label-right"
          ref="videoLabelRight"
        ></label>
        <select
          ref="focalLengthRight"
          id="focalLengthRight"
          name="focalLengthRight"
          style="display: none"
          @change="setFocalLengthChange($event, 'RIGHT')"
        >
          <option
            v-for="option in store.focalLengthOptionsRight"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <select
          ref="transmissionRight"
          id="transmissionRight"
          name="transmissionRight"
          style="display: none"
          @change="setTransmission($event, 'RIGHT')"
        >
          <option
            v-for="option in store.transmissionsRight"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="video-box">
        <SaveBtn
          :status="rightSaveBtnStatus"
          @click="addWishlist('RIGHT')"
          :loading="rightSaveBtnLoading"
          :viewCondition="store.pauseVideo"
        />
        <div
          class="spinner-container-right"
          id="spinner-right"
          ref="spinnerRightRef"
        >
          <div class="spinner-load"></div>
        </div>
        <video
          id="video2"
          ref="video2Ref"
          class="video"
          preload="auto"
          @waiting="syncVideos('video2')"
          @canplaythrough="CanPlayThrough('video2')"
          @ended="reloadPage()"
          disablepictureinpicture
          oncontextmenu="return false;"
          controlslist="nodownload noremoteplayback noplaybackrate"
          @pause="store.setPauseVideo()"
          @play="store.setPlayVideo()"
        >
          <source src="" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div class="video-controls video-controls-right">
          <div
            class="video-control-option video-full-screen"
            id="fullScreenRight"
            @click="enterFullscreen('RIGHT')"
          >
            <img src="../assets/img/fullscreen.svg" width="12" />
          </div>
        </div>
      </div>
    </div>
    <div class="web-video-controls">
      <WebVideoControls />
    </div>
  </div>
</template>

<script setup>
import { useLensesStore } from "../stores/Lenses";
import { onMounted, watch, ref, onUnmounted, defineEmits, computed } from "vue";
import { storeToRefs } from "pinia";
import WebVideoControls from "./WebVideoControls.vue";
import SaveBtn from "@/components/Selects/SaveBtn.vue";

import { useOverlayStore } from "@/stores/Overlay";
import { ampli } from "@/ampli";
import { useWishlistStore } from "@/stores/Wishlist";
import { addToWishlist, removeFromWishlist } from "@/api/wishlist";
import { useRouter } from "vue-router";


const emit = defineEmits([
  "updateLeftSingleDetails",
  "updateRightSingleDetails",
]);

const spinnerLeftRef = ref(null);
const spinnerRightRef = ref(null);
const video1Ref = ref(null);
const video2Ref = ref(null);
const videoLabelLeft = ref(null);
const videoLabelRight = ref(null);
const focalLengthLeft = ref(null);
const focalLengthRight = ref(null);
const transmissionLeft = ref(null);
const transmissionRight = ref(null);
const iconLeft = ref(null);
const iconRight = ref(null);
const leftSingleVideoDetail = ref(null);
const rightSingleVideoDetail = ref(null);
const leftSaveBtnStatus = ref(null);
const rightSaveBtnStatus = ref(null);
const leftSaveBtnLoading = ref(false);
const rightSaveBtnLoading = ref(false);

const overlayStore = useOverlayStore();

const savedVideos = computed(() => wishlistStore.wishlist);

const wishlistStore = useWishlistStore();

const router = useRouter();

let currentPathObject = router.currentRoute.value;

const store = useLensesStore();
const {
  LeftSelectedVideoId,
  RightSelectedVideoId,
  LeftFocalLength,
  RightFocalLength,
  LeftTransmission,
  RightTransmission,
  playVideo,
  pauseVideo,
} = storeToRefs(store);

onMounted(async () => {
  //set the default lenses name on page load

  overlayStore.showLoadingOverlay();

  const defaultLeft =
    store.defaultLeftVideoId || process.env.VUE_APP_DEFAULT_LEFT;
  const defaultRight =
    store.defaultRightVideoId || process.env.VUE_APP_DEFAULT_RIGHT;

  store.setLeftSelectedVideoId(defaultLeft);
  store.setRightSelectedVideoId(defaultRight);

  const leftAnchor = document.getElementById(defaultLeft);
  const rightAnchor = document.getElementById(defaultRight);

  leftAnchor.parentElement.classList.add("active-li");
  rightAnchor.parentElement.classList.add("active-li");

  savedVideos.value = wishlistStore.wishlist;

  generateButton();
});

onUnmounted(() => {
  store.LeftSelectedVideoId = null;
  store.RightSelectedVideoId = null;
  store.LeftFocalLength = null;
  store.RightFocalLength = null;
});

function generateButton() {
  const playPauseControls = document.getElementById("playPauseControls");
  if (playPauseControls) playPauseControls.style.display = "";
}

function CanPlayThrough(videoId) {
  if (videoId === "video1") {
    store.toggleLeftVideoLoaded();
  } else {
    store.toggleRightVideoLoaded();
  }
  if (store.LeftVideoLoaded === true && store.RightVideoLoaded === true) {
    generateButton();
  }

  overlayStore.hideLoadingOverlay();
}

function enterFullscreen(target) {
  store.enterFullscreen(target === "LEFT" ? video1Ref : video2Ref);
}

function reloadPage() {
  video1Ref.value.load();
  video2Ref.value.load();
}

function setFocalLengthChange(event, target) {
  const selectedFocalLength = event.target.value;
  target === "LEFT"
    ? store.setLeftFocalLength(selectedFocalLength)
    : store.setRightFocalLength(selectedFocalLength);
}

function setTransmission(event, target) {
  const selectedTransmission = event.target.value;
  target === "LEFT"
    ? store.setLeftTransmission(selectedTransmission)
    : store.setRightTransmission(selectedTransmission);
}

function reloadTransmission(target) {
  const lensesDetails =
    target === "LEFT"
      ? store.LeftSelectedVideoDetails
      : store.RightSelectedVideoDetails;

  lensesDetails.sort((a, b) => {
    const focalLengthA = a.focalLength
      ? parseFloat(a.focalLength.replace("mm", ""))
      : 0;
    const focalLengthB = b.focalLength
      ? parseFloat(b.focalLength.replace("mm", ""))
      : 0;
    const transmissionA = a.transmission
      ? parseFloat(a.transmission.replace("T", ""))
      : 0;
    const transmissionB = b.transmission
      ? parseFloat(b.transmission.replace("T", ""))
      : 0;
    const focalDiff = focalLengthA - focalLengthB;
    if (focalDiff !== 0) {
      return focalDiff;
    }
    return transmissionA - transmissionB;
  });
  const transmissonOptions =
    target === "LEFT"
      ? lensesDetails.filter((s) => s.focalLength === store.LeftFocalLength)
      : lensesDetails.filter((s) => s.focalLength === store.RightFocalLength);

  // delete previous transmissions and set a new one
  const transmissionId =
    target === "LEFT" ? "transmissionLeft" : "transmissionRight";
  const transmissionElement = document.getElementById(transmissionId);

  while (transmissionElement.options.length > 0) {
    transmissionElement.remove(0);
  }

  target === "LEFT"
    ? store.setLeftTransmission(transmissonOptions[0]?.transmission)
    : store.setRightTransmission(transmissonOptions[0]?.transmission);

  transmissonOptions?.forEach((option) => {
    const optionElement = document.createElement("option");
    optionElement.value = option.transmission;
    optionElement.text = option.transmission;

    // optionElement.selected =
    //   option.transmission === selectedTransmission ? "selected" : "";
    transmissionElement.appendChild(optionElement);
  });
  transmissionElement.style.display = "block";
}

function syncVideos(target) {
  let currentTime =
    target === "video1"
      ? video1Ref.value.currentTime
      : video2Ref.value.currentTime;
  video1Ref.value.pause();
  video2Ref.value.pause();
  video1Ref.value.currentTime = currentTime;
  video2Ref.value.currentTime = currentTime;
}

/*
AMPLITUDE: Track lens watched
Triggers when video is played.
*/
const trackLensesWatched = () => {
  const selectedLensRight = store.RightSelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.RightFocalLength &&
      s.transmission === store.RightTransmission
  );
  const selectedLensLeft = store.LeftSelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.LeftFocalLength &&
      s.transmission === store.LeftTransmission
  );
  // ampli.lensWatched({
  //   lensId: selectedLensLeft.id,
  //   lensName: selectedLensLeft.name,
  //   lensCategoryId: selectedLensLeft.lensCategoryId,
  //   focalLength: selectedLensLeft.focalLength,
  //   transmission: selectedLensLeft.transmission,
  //   comparedLensId: selectedLensRight.id,
  // });
  ampli.lensVideoPlayed({
    leftLensId: selectedLensLeft.id,
    leftLensName: selectedLensLeft.name,
    leftLensCategoryId: selectedLensLeft.lensCategoryId,
    leftFocalLength: selectedLensLeft.focalLength,
    leftTransmission: selectedLensLeft.transmission,
    rightLensId: selectedLensRight.id,
    rightLensName: selectedLensRight.name,
    rightLensCategoryId: selectedLensRight.lensCategoryId,
    rightFocalLength: selectedLensRight.focalLength,
    rightTransmission: selectedLensRight.transmission,
  });
};

// Wishlist feature

const generateLeftSaveIcon = () => {
  if (!leftSingleVideoDetail.value) return;
  const { id } = leftSingleVideoDetail.value;

  let idArray = [];

  savedVideos.value?.forEach((obj) => {
    if (!obj.sceneImageUrl) idArray.push(obj.lensId);
  });

  if (idArray.includes(id)) {
    leftSaveBtnStatus.value = "saved";
  } else {
    leftSaveBtnStatus.value = "unsaved";
  }
};

const generateRightSaveIcon = () => {
  if (!rightSingleVideoDetail.value) return;
  const { id } = rightSingleVideoDetail.value;

  let idArray = [];

  savedVideos.value?.forEach((obj) => {
    if (!obj.sceneImageUrl) idArray.push(obj.lensId);
  });

  if (idArray.includes(id)) {
    rightSaveBtnStatus.value = "saved";
  } else {
    rightSaveBtnStatus.value = "unsaved";
  }
};

const handleUnsave = async (lensId, target) => {
  const wishlistData = savedVideos.value.find(
    (wishlist) => wishlist.lensId === lensId && !wishlist.sceneImageUrl
  );

  await removeFromWishlist(wishlistData.id);

  wishlistStore.removeFromWishlist(wishlistData.id);

  target === "LEFT"
    ? (leftSaveBtnLoading.value = false)
    : (rightSaveBtnLoading.value = false);
};

const addWishlist = async (target) => {
  let videoObject;
  let status;
  let lensCategoryName;

  if (target === "LEFT") {
    videoObject = leftSingleVideoDetail.value;
    status = leftSaveBtnStatus.value;

    lensCategoryName = store.data.find(
      (lens) => lens.id === leftSingleVideoDetail.value?.lensCategoryId
    )?.name;

    leftSaveBtnLoading.value = true;
  } else {
    videoObject = rightSingleVideoDetail.value;
    status = rightSaveBtnStatus.value;

    lensCategoryName = store.data.find(
      (lens) => lens.id === rightSingleVideoDetail.value?.lensCategoryId
    )?.name;

    rightSaveBtnLoading.value = true;
  }

  const {
    id: lensId,
    name,
    focalLength,
    transmission,
    flareImage,
    preview,
    videoPath,
  } = videoObject;

  if (status === "saved") {
    handleUnsave(lensId, target);
    return;
  }

  const payload = {
    lensId,
    name,
    focalLength,
    transmission,
    flareImage: flareImage ? flareImage : preview,
    videoPath,
    categoryName: lensCategoryName,
  };

  /*
    AMPLITUDE: Track lens saved
    Triggers when a lens save button is clicked
  */
  ampli.lensSaved({
    lensId: lensId,
    lensName: name,
    focalLength: focalLength,
    transmission: transmission,
    page: currentPathObject?.fullPath,
  });
  const response = await addToWishlist(payload);

  if (response.status === 200) {
    wishlistStore.wishlist = [...wishlistStore.wishlist, response.data];
  }

  leftSaveBtnLoading.value = false;
  rightSaveBtnLoading.value = false;
};

watch(savedVideos, () => {
  generateLeftSaveIcon();
  generateRightSaveIcon();
});

watch(rightSingleVideoDetail, () => {
  generateLeftSaveIcon();
  generateRightSaveIcon();

  //passing data to body for lens details
  emit("updateRightSingleDetails", rightSingleVideoDetail.value);
});
watch(leftSingleVideoDetail, () => {
  generateLeftSaveIcon();
  generateRightSaveIcon();

  //passing data to body for lens details
  emit("updateLeftSingleDetails", leftSingleVideoDetail.value);
});

//watcher to monitor change in leftvideo Id
watch(LeftSelectedVideoId, () => {
  const Leftitem = document.getElementById(store.LeftSelectedVideoId);

  if (store.searchIsActive) {
    const navEntities = document.querySelectorAll(".nav-entity-left");
    navEntities.forEach((item) => {
      item.style.transform = "rotateX(0deg)";
    });
  }

  store.scrollToItem(Leftitem);
  reloadPage();
  store.setLeftSelectedVideoDetails();
  store.generateVideoLabelText(videoLabelLeft, "LEFT");
  store.setLeftFocalLength(store.LeftSelectedVideoDetails[0].focalLength);
  store.setLeftTransmission(store.LeftSelectedVideoDetails[0].transmission);
  store.generateDropDown("LEFT", focalLengthLeft, transmissionLeft);
  store.loadDefaultVideoPath(spinnerLeftRef, video1Ref, "LEFT");
});

//watcher to monitor change in leftvideo Id
watch(RightSelectedVideoId, () => {
  reloadPage();
  const RightItem = document.getElementById(store.RightSelectedVideoId);

  if (store.searchIsActive) {
    const navEntities = document.querySelectorAll(".nav-entity-right");
    navEntities.forEach((item) => {
      item.style.transform = "rotateX(0deg)";
    });
  }
  store.scrollToItem(RightItem);
  store.setRightSelectedVideoDetails();
  store.generateVideoLabelText(videoLabelRight, "RIGHT");
  store.setRightFocalLength(store.RightSelectedVideoDetails[0].focalLength);
  store.setRightTransmission(store.RightSelectedVideoDetails[0].transmission);
  store.generateDropDown("RIGHT", focalLengthRight, transmissionRight);
  store.loadDefaultVideoPath(spinnerRightRef, video2Ref, "RIGHT");
  store.setPauseVideo();
});

// watcher to monitor change in left focal length
watch(LeftFocalLength, () => {
  reloadPage();
  store.setLeftSelectedVideoDetails();

  reloadTransmission("LEFT");
  const lensesItem = store.LeftSelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.LeftFocalLength &&
      s.transmission === store.LeftTransmission
  );
  leftSingleVideoDetail.value = lensesItem;
  store.generateIcon(lensesItem?.icon, iconLeft);
  store.setVideoPathOnChange(lensesItem, video1Ref);
  store.setPauseVideo();

  generateLeftSaveIcon();
});

//watcher to monitor change in left focal length
watch(RightFocalLength, () => {
  reloadPage();
  store.setRightSelectedVideoDetails();
  reloadTransmission("RIGHT");
  const lensesItem = store.RightSelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.RightFocalLength &&
      s.transmission === store.RightTransmission
  );
  rightSingleVideoDetail.value = lensesItem;

  store.generateIcon(lensesItem?.icon, iconRight);
  store.setVideoPathOnChange(lensesItem, video2Ref);
  store.setPauseVideo();
});

watch(LeftTransmission, () => {
  reloadPage();
  store.setLeftSelectedVideoDetails();
  const lensesItem = store.LeftSelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.LeftFocalLength &&
      s.transmission === store.LeftTransmission
  );
  leftSingleVideoDetail.value = lensesItem;

  store.generateIcon(lensesItem?.icon, iconLeft);
  store.setVideoPathOnChange(lensesItem, video1Ref);
  store.setPauseVideo();
});

watch(RightTransmission, () => {
  reloadPage();
  store.setRightSelectedVideoDetails();
  const lensesItem = store.RightSelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.RightFocalLength &&
      s.transmission === store.RightTransmission
  );

  rightSingleVideoDetail.value = lensesItem;

  store.generateIcon(lensesItem?.icon, iconRight);
  store.setVideoPathOnChange(lensesItem, video2Ref);
  store.setPauseVideo();
});

watch(playVideo, () => {
  if (store.playVideo === true) {
    trackLensesWatched();
    video1Ref.value.play();
    video2Ref.value.play();
  }
});

watch(pauseVideo, () => {
  if (store.pauseVideo === true) {
    video1Ref.value.pause();
    video2Ref.value.pause();
  }
});
</script>

<style scoped>
.column.middle {
  position: relative;
}

.web-video-controls {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
