<template>
  <div :class="side + '-sidebar'">
    <ul
      :class="'scrollable-list-' + side"
      ref="viewportListRef"
      :id="side === 'left' ? 'viewportList' + 'Left' : 'viewportList' + 'Right'"
      @scroll="store.snapToClosestOption(viewportListRef)"
    >
      <li v-for="item in store.data" :key="item.id">
        <a
          class="nav-title nav-entity"
          :class="side === 'left' ? 'nav-entity-left' : 'nav-entity-right'"
          >{{ item.name }}</a
        >
        <ul class="nested-list" v-if="item.lenses && item.lenses.length">
          <li
            v-for="lens in uniqueLenses(item.lenses)"
            :key="lens.name"
            class="nav-item nav-entity"
            :class="side === 'left' ? 'nav-entity-left' : 'nav-entity-right'"
          >
            <a
              :ref="getLinkId(lens.name, side)"
              :id="getLinkId(lens.name, side)"
              href="javascript:;"
              class="nav-link"
              @click="setSelection(lens.name, side)"
              >{{ lens.name }}</a
            >
            <span class="new-badge" v-if="lens.isNew">NEW</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { useLensesStore } from "@/stores/Lenses";
import { onMounted } from "vue";
import { ref } from "vue";

const store = useLensesStore();
const viewportListRef = ref(null);
function getLinkId(name, side) {
  // Convert to lowercase and replace spaces with hyphens
  return name.toLowerCase().replace(/\s+/g, "-") + "-" + side;
}
function setSelection(name, side) {
  const id = getLinkId(name, side);
  side === "left"
    ? store.setLeftSelectedVideoId(id)
    : store.setRightSelectedVideoId(id);
}

onMounted(() => {
  store.addHeightOntopAndBottom(viewportListRef);
});

const uniqueLenses = (lensCategory) => {
  if (lensCategory) {
    const uniqueLenses = {};

    // Iterate through lenses in the category
    lensCategory.forEach((lens) => {
      const name = lens.name;
      const isNew = lens.isNew;

      // If lens name is not already in uniqueLenses or isNew
      if (!uniqueLenses[name] || isNew) {
        uniqueLenses[name] = isNew;
      }
    });

    // Convert uniqueLenses object to an array of objects
    const uniqueLensArray = Object.keys(uniqueLenses).map((name) => ({
      name,
      isNew: uniqueLenses[name],
    }));

    return uniqueLensArray;
  }
  return [];
};
</script>
<script>
export default {
  props: {
    side: String,
  },
};
</script>

