<template>
  <div class="secondary-body">
    <section>
      <div class="container">
        <div class="column left"></div>
        <div class="column middle account-middle">
          <div class="site-logo">
            <img :src="userStore.logoSource" alt="cineflares" />
          </div>
          <div class="account-dashboard">
            <div class="account-dash-sidebar">
              <RouterLink to="profile" class="sidebar-item"
                ><i class="fas fa-user-circle"></i> Profile</RouterLink
              >
              <RouterLink to="password" class="sidebar-item"
                ><i class="fas fa-key"></i> Password</RouterLink
              >
              <RouterLink to="payment" class="sidebar-item"
                ><i class="fas fa-credit-card"></i> Payment</RouterLink
              >
            </div>
            <div class="account-dash-content">
              <RouterView />
            </div>
          </div>
        </div>

        <div class="column right"></div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useUsersStore } from "@/stores/Users";
import { onMounted, onUnmounted } from "vue";

const userStore = useUsersStore();

onMounted(() => {
  document.querySelector("body").classList.add("backgroundImage");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("backgroundImage");
});
</script>

<style scoped>
.account-middle {
  flex-direction: column;
}

.account-dashboard {
  background: #1a1e1e;
  margin-top: 40px;
  padding: 30px;
  display: flex;
  gap: 30px;
}

.account-dash-sidebar {
  padding-right: 60px;
  border-right: 1px solid rgba(255, 255, 255, 0.103);
}

.account-dash-sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar-item {
  text-decoration: none;
  color: white;
  opacity: 0.7;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.sidebar-item.router-link-active {
  opacity: 0.9;
  color: rgb(45, 120, 250);
}

.sidebar-item i {
  margin-right: 10px;
  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .account-middle {
    display: flex !important;
  }

  .site-logo {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .account-dashboard {
    flex-direction: column;
    padding: 30px 20px;
  }

  .account-dash-sidebar {
    flex-direction: row;
    border-bottom: 1px solid rgba(255, 255, 255, 0.103);
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    gap: 20px;
  }

  .sidebar-item {
    font-size: 14px;
  }
  .sidebar-item i {
    margin-right: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .sidebar-item i {
    display: none;
  }
}
</style>
