import {
  Form as VeeForm,
  Field as VeeField,
  defineRule,
  ErrorMessage,
  configure,
} from "vee-validate";
import {
  required,
  min,
  max,
  alpha_spaces as alphaSpaces,
  email,
  min_value as minValue,
  max_value as maxValue,
  confirmed,
  not_one_of,
} from "@vee-validate/rules";
const convertToLowerUpper = (inputString) => {
  // Split the inputString into an array of words
  const words = inputString.split(/([A-Z][a-z]+)/);

  // Capitalize the first letter of each word and join them with a space
  const result = words
    .filter((word) => word !== "")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return result;
};

export default {
  install(app) {
    app.component("VeeForm", VeeForm);
    app.component("VeeField", VeeField);
    app.component("ErrorMessage", ErrorMessage);

    defineRule("required", required);
    defineRule("tos", required);
    defineRule("recaptcha", required);
    defineRule("min", min);
    defineRule("max", max);
    defineRule("alpha_spaces", alphaSpaces);
    defineRule("email", email);
    defineRule("min_value", minValue);
    defineRule("max_value", maxValue);
    defineRule("password_mismatch", confirmed);
    defineRule("excluded", not_one_of);
    defineRule("oneUpperCase", (value) => {
      if (!/[A-Z]/.test(value)) {
        return "The password must have atleast one uppercase letter.";
      }
      return true;
    });
    defineRule("oneLowerCase", (value) => {
      if (!/[a-z]/.test(value)) {
        return "The password must have atleast one lowercase letter.";
      }
      return true;
    });
    defineRule("oneSpecialCharacter", (value) => {
      if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value)) {
        return "The password must have atleast one special character.";
      }
      return true;
    });

    configure({
      generateMessage: (ctx) => {
        const messages = {
          required: `${convertToLowerUpper(ctx.field)} is required.`,
          min: `${convertToLowerUpper(ctx.field)} is too short.`,
          max: `${convertToLowerUpper(ctx.field)} is too long.`,
          email: `${convertToLowerUpper(ctx.field)} must be valid email.`,
          alpha_spaces: `${convertToLowerUpper(
            ctx.field
          )} must only contain aplanumerical characters.`,
          min_value: `${convertToLowerUpper(ctx.field)} is too low.`,
          max_value: `${convertToLowerUpper(ctx.field)} is too long.`,
          excluded: `You are not allowed to ue this value for the field ${ctx.field}`,
          password_mismatch: `The passwords do not match.`,
          tos: `You must accept the terms of service.`,
          recaptcha: `Please verify that you are human.`,
        };

        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `The field ${ctx.field} is invalid.`;

        return message;
      },
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
    });
  },
};
