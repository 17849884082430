import {
  flareColorChoices,
  flareFOVChoices,
  flareIntensityChoices,
  flareTypeChoices,
  lensEraChoices,
} from "./constants";

export const getLensPropOptionLabel = (prop, value) => {
  switch (prop) {
    case "flareColor":
      return getLabels(value, flareColorChoices);

    case "flareType":
      return getLabels(value, flareTypeChoices);

    case "lensEra":
      return getLabels(value, lensEraChoices);

    case "flareIntensity":
      return getLabels(value, flareIntensityChoices);

    case "FOV":
      return getLabels(value, flareFOVChoices);

    default:
      return null;
  }
};

function getLabels(idsString, mapArray) {
  if (!idsString) return null;
  const idToNameMap = mapArray.reduce((map, choice) => {
    map[choice.id] = choice.name;
    return map;
  }, {});

  return idsString
    .split(",")
    .map((id) => idToNameMap[id])
    .join(", ");
}
