import client from "./client";

export const createFolder = async (name) => {
  try {
    const { data } = await client.post(`/folder`, { name });
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};

export const getFolders = async () => {
  try {
    const { data } = await client.get(`/folder`);
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};

export const getFolderDetails = async (folderId) => {
  try {
    const { data } = await client.get(`/folder/${folderId}`);
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return { error: response.data };

    return { error: err.message || err };
  }
};

export const addWishlistToFolders = async (folderIds, wishlistId) => {
  try {
    const { data } = await client.post(`/folder/add-wishlist`, {
      folderIds,
      wishlistId,
    });
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};

export const deleteFolder = async (folderId) => {
  try {
    const { data } = await client.delete(`/folder/delete/${folderId}`);
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return { error: response.data };

    return { error: err.message || err };
  }
};

export const updateFolder = async (id, name) => {
  try {
    const { data } = await client.put(`/folder/update`, { id, name });
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return { error: response.data };

    return { error: err.message || err };
  }
};

export const removeSelectFromFolder = async (folderId, wishlistId) => {
  try {
    const { data } = await client.post(`/folder/remove-wishlist`, {
      folderId,
      wishlistId,
    });
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return { error: response.data };

    return { error: err.message || err };
  }
};
