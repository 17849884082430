import { defineStore } from "pinia";
import axios from "axios";
import { auth } from "../firebase/init";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { signOut } from "@firebase/auth";
import client from "@/api/client";

export const useUsersStore = defineStore("users", {
  state: () => ({
    base_URL: process.env.VUE_APP_BASE_URL,
    isLoggedInValue: false,
    userRole: null,
    userDetails: null,
    profileLoaded: false,
  }),
  getters: {
    logoSource() {
      // Use dynamic import and require based on user's premium status
      return this.userDetails?.isPremiumUser
        ? require("@/assets/img/cineflares-pro-logo.webp")
        : require("@/assets/img/cineflares-logo.webp");
    },
  },
  actions: {
    async signup(payload) {
      try {
        const API_URL = `${this.base_URL}/auth`;
        const response = await client.post(API_URL, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return { error: false, data: response };
      } catch (error) {
        console.error("Error posting data:", error);
        if (error.response) {
          return { error: true, message: error.response.data.message };
        } else {
          // If no response property, construct a custom error object
          return { error: true, message: "An error occurred.", status: 404 };
        }
      }
    },
    async login(email, password) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        this.isLoggedInValue = true;
        return "success";
      } catch (error) {
        console.log(error.message);
        return error.message;
      }
    },
    updateLoginStatus(response) {
      const customAttributes = JSON.parse(
        response.user?.reloadUserInfo?.customAttributes
      );
      this.userRole = customAttributes?.role;
      this.isLoggedInValue = !this.isLoggedInValue;
    },
    async logOut() {
      await signOut(auth);
      this.isLoggedInValue = false;
      this.userDetails = null;
      this.profileLoaded = false;
    },
    async getProfile() {
      try {
        const response = await client.get(
          `${process.env.VUE_APP_BASE_URL}/users/profile`
        );

        if (response.data) {
          this.userDetails = response.data;
          this.profileLoaded = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
});

// Axios interceptor setup
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.data.code === "auth/id-token-expired") {
      useUsersStore().logOut();

      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
