<template>
  <div class="popup-container" @click="handleAlertClose">
    <div class="popup-box">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emits = defineEmits(["closeAlert"]);

const handleAlertClose = (e) => {
  if (!e.target.classList.contains("popup-container")) return;

  emits("closeAlert");
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  inset: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-box {
  width: 100%;
  margin: 0 20px;
  max-width: 900px;
  height: 80vh;
  overflow: auto;
  background: #1a1e1e;
  padding: 20px;
  position: relative;
  animation: grow 0.5s ease;
}

@media screen and (max-width: 1024px) {
  .popup-box {
    padding-inline: 0;
  }
}

.popup-cross {
  width: 10px;
  position: absolute;
  top: -10px;
  right: 10px;
  cursor: pointer;
  opacity: 0.6;
}

@keyframes grow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
