<template>
  <Transition name="fade">
    <div
      class="vide-save-btn"
      :class="{
        'scene-save': sceneSave && sceneSave.isScene,
        'left-side-save': sceneSave && sceneSave.isLeft,
      }"
      v-if="props.viewCondition"
    >
      <i class="fas fa-spinner fa-spin" v-if="loading"></i>
      <div v-else-if="props.status === 'saved'" class="save-status-flex">
        <img src="@/assets/img/save-tick.svg" alt="" />
        <span>Saved</span>
      </div>
      <div v-else-if="props.status === 'unsaved'" class="save-status-flex">
        <img src="@/assets/img/save-icon.svg" alt="" />
        <span>Save</span>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["status", "loading", "viewCondition", "sceneSave"]);
</script>

<style scoped>
.save-status-flex {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>
