import { initializeApp } from "@firebase/app";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "@firebase/auth";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
};

// init firebase
const app = initializeApp(firebaseConfig);

// init firestore service

const auth = getAuth();

export {
  app,
  auth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
};
