<template>
  <div id="playPauseControls" ref="playPauseControlsRef" style="display: none">
    <div
      id="playButton"
      ref="playButton"
      v-if="store.videoControlBtn === 'play'"
    >
      <img
        src="../assets/img/play.png"
        class="play-button"
        id="playButtonImg"
        @mouseover="onHover('PLAY')"
        @mouseout="onMouseOut('PLAY')"
        @click="onPlayButtonClick()"
      />
    </div>

    <div
      id="pauseButton"
      v-show="store.videoControlBtn === 'pause'"
      ref="pauseButton"
    >
      <img
        src="../assets/img/pause.png"
        class="pause-button"
        id="pauseButtonImg"
        @mouseover="onHover('PAUSE')"
        @mouseout="onMouseOut('PAUSE')"
        @click="onPauseButtonClick()"
      />
    </div>
  </div>
</template>

<script setup>
import { useLensesStore } from "@/stores/Lenses";
import { ref } from "vue";

const store = useLensesStore();
const playButton = ref(null);
const pauseButton = ref(null);
function onHover(target) {
  const ButtonImg =
    target === "PLAY"
      ? document.getElementById("playButtonImg")
      : document.getElementById("pauseButtonImg");
  ButtonImg.style.transform = "scale(1.25)";
  ButtonImg.style.transition = "transform 0.5s ease-in-out";
}

function onMouseOut(target) {
  const ButtonImg =
    target === "PLAY"
      ? document.getElementById("playButtonImg")
      : document.getElementById("pauseButtonImg");
  ButtonImg.style.transform = "scale(1)";
  ButtonImg.style.transition = "transform 0.5s ease-in-out";
}

function onPlayButtonClick() {
  store.setPlayVideo();
  store.videoControlBtn = "pause";
}

function onPauseButtonClick() {
  store.setPauseVideo();
  store.videoControlBtn = "play";
}
</script>

<style scoped></style>
