<script setup>
import "./styles/main.css";
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import MobileHeader from "./components/MobileComponents/MobileHeader.vue";
import MobileFooter from "./components/MobileComponents/MobileFooter.vue";
import { onBeforeMount, watch } from "vue";
import { useScreenStore } from "./stores/Screen";
import { storeToRefs } from "pinia";
import { useOverlayStore } from "@/stores/Overlay";
import * as amplitude from "@amplitude/analytics-browser";
import { ampli } from "./ampli";
import { auth } from "./firebase/init";
import { useUsersStore } from "./stores/Users";
import ToastMessage from "./components/form/ToastMessage.vue";
import { useWishlistStore } from "./stores/Wishlist";
const overlayStore = useOverlayStore();
const userStore = useUsersStore();

const app = document.getElementById("app");
const screenStore = useScreenStore();
const wishListStore = useWishlistStore();
const { isMobile } = storeToRefs(screenStore);

watch(isMobile, async () => {
  calculateStyles();
});

onBeforeMount(() => {
  screenStore.checkWindowSize();
  //persist the user authentication after page refresh
  initAmpli();

  if (auth.currentUser) {
    const customAttributes = JSON.parse(
      auth.currentUser?.reloadUserInfo?.customAttributes
    );

    userStore.userRole = customAttributes.role;
    userStore.isLoggedInValue = true;
    wishListStore.fetchWishlist();
  }
});

window.addEventListener("resize", () => {
  screenStore.checkWindowSize();
});

/*
AMPLITUDE: Config and load ampli
Triggers on mount and starts 
tracking default events like
page views.
*/
const initAmpli = () => {
  ampli.load({
    environment: process.env.NODE_ENV,
    client: {
      configuration: {
        defaultTracking: true,
        logLevel:
          process.env.NODE_ENV === "development"
            ? amplitude.Types.LogLevel.Debug
            : amplitude.Types.LogLevel.Error,
      },
    },
  });
  const { currentUser } = auth ?? {};
  if (currentUser) {
    ampli.identify(currentUser?.uid, {
      emailId: currentUser?.email,
    });
  }
};

const calculateStyles = () => {
  if (!screenStore.isMobile) {
    document.body.classList.remove("mobileBody");
    document.body.classList.add("webBody");
    app.classList.remove("mobileApp");
  } else {
    document.body.classList.add("mobileBody");
    document.body.classList.remove("webBody");
    app.classList.add("mobileApp");
  }
};
</script>

<template>
  <ToastMessage />
  <div
    class="loading-overlays"
    id="loading-overlays"
    ref="LoadingOverlayRef"
    v-if="overlayStore.showOverlay"
  >
    <div class="spinners"></div>
  </div>
  <div v-if="!isMobile">
    <HeaderComponent />
    <RouterView />
    <FooterComponent />
  </div>
  <div
    v-else
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      height: 100%;
    "
  >
    <div>
      <MobileHeader />
      <RouterView />
    </div>
    <MobileFooter />
  </div>
</template>
