<template>
  <div class="pricingContainer">
    <div class="pricingLeftContainer">
      <div class="pricing-left-container">
        <div>
          <p class="pricing-title">
            Join CINEFLARES
            <span class="pro">PRO</span>
          </p>
        </div>
        <div class="pricing-card-container">
          <FreeCard />
          <ProYearlyCard />
          <ProMonthlyCard />
        </div>
        <div class="warningMessage">
          <span class="span"
            >If you want to support this project, please consider becoming a PRO
            member. Your contribution will be used to grow our lens library and
            work on expanded features.</span
          >
        </div>
      </div>
    </div>
    <div class="pricingRightContainer">
      <CarouselComponent />
    </div>
  </div>
</template>

<script setup>
import CarouselComponent from "@/components/CarouselComponent.vue";
import FreeCard from "@/components/PricingCards/FreeCard.vue";
import ProYearlyCard from "@/components/PricingCards/ProYearlyCard.vue";
import ProMonthlyCard from "@/components/PricingCards/ProMonthlyCard.vue";
import { onBeforeMount, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useUsersStore } from "@/stores/Users";
import config from "@/config/config";

const router = useRouter();

const userStore = useUsersStore();

onMounted(async () => {
  document.querySelector("body").classList.add("backgroundImage");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("backgroundImage");
});

onBeforeMount(() => {
  if (!userStore.isLoggedInValue) return;

  if (!config.enablePayment) router.push("/");
});
</script>

<style>
.pricingContainer {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  padding: 50px 20px;
}

.pricingLeftContainer {
  flex: 2;
  margin-right: 1rem;
}

.pricingRightContainer {
  flex: 1;
}

.pricing-card {
  background-color: #102034;
  word-wrap: break-word;
  margin: 10px;
  border-radius: 15px;
  padding: 7px 10px;
  box-sizing: border-box;
  position: relative;
  width: 33%;
  max-width: 300px;
}

.pricing-card-subheading {
  font-size: 12px;
}

.pricing-card-divElement .pro {
  font-size: 17px;
}
h1 {
  text-align: center;
}

.cine-info {
  font-size: 15px;
}

.pricing-card-list-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.pricing-card-tick-image {
  width: 12px;
  padding-top: 5px;
}
.warningMessage {
  text-align: center;
  width: 25%;
  color: gray;
  margin: auto;
  margin-top: 20px;
}

.pricing-card-listElement {
  margin: 5px;
  flex-grow: 1;
  font-size: 14px;
}

.pricing-card-divElement {
  flex-grow: 1;
  margin-left: 5px;
  font-size: 16px;
}

.pricing-card-underline-element {
  text-decoration: underline;
}

.pricing-card-inline-element {
  display: inline;
}

.pricing-card-relativeElement {
  position: relative;
}

.pricing-card-list {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  gap: 2px;
}

.pricing-card-subscribe-button {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  background-color: #fffc84;
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
}

.pricing-card-subscribe-button:hover {
  transform: scale(1.04);
}

.span {
  font-size: 12px;
}

.pricing-card-container {
  display: flex;
  margin-top: 0;
  justify-content: center;
}

.learnMore {
  background-color: transparent;
  color: white;
  padding: 8px;
  margin: 7px;
}

.span-pro {
  padding: 0 7px;
  background: linear-gradient(to right, #86c1e2, #aca8e0);
  border-radius: 2px;
  font-weight: 500;
  letter-spacing: 1px;
}
.pricing-card-center-div {
  justify-content: center;
  display: flex;
  gap: 10px;
  align-items: flex-end;
  margin-bottom: 10px;
}
.pricing-card-price {
  font-size: 48px;
  font-weight: bold;
  margin-left: 50px;
}
.pricing-bold {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.5px;
}
.pricing-card-center-div-details {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.pricing-card-divElement-heading {
  margin: 0;
}
.pricing-card-learnMoreContainer {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
}

.pricing-card-learnMoreContainer .pricing-card-inline-element {
  margin: 0;
  margin-left: 30px;
  font-size: 12px;
}

.pricing-card-learnMore-button {
  background-color: transparent;
  color: white;
  padding: 8px;
  margin: 0 7px;
  border: 2px solid #07101c;
  border-radius: 5px;
  cursor: pointer;
}

.pricing-card:first-child {
  height: fit-content;
}

.card:hover {
  box-shadow: 0 0 0 1px #f0f0f0;
}

.learnMoreContainer {
  /* margin-top: auto; */
  font-size: 10px;
}

.pricing-title {
  text-align: center;
  position: relative;
  font-size: 40px;
  margin-bottom: 30px;
}

.pricing-card-underline-element {
  font-size: 11px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 0.9;
  margin-top: 4px;
  position: relative;
  width: fit-content;
}

.pricing-card-underline-element::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: rgba(255, 255, 255, 0.719);
}

@media only screen and (max-width: 1500px) {
  .pricing-card-learnMoreContainer {
    flex-wrap: wrap;
  }

  .pricing-card-price {
    font-size: 40px;
  }
  .pricing-bold {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .pricingContainer {
    flex-direction: column;
    gap: 40px;
  }
}

@media screen and (max-width: 768px) {
  .pricing-card-container {
    flex-direction: column;
  }

  .pricing-card {
    width: 100%;
    max-width: none;
  }

  .pricing-title {
    margin-top: 0;
    font-size: 32px;
  }

  .warningMessage {
    width: 90%;
  }
}
</style>
