import { defineStore } from "pinia";
export const useToastStore = defineStore("toast", {
  state: () => ({
    toastVisible: false,
    toastType: "success",
    toastMessage: "",
    toastTimeout: null,
  }),
  getters: {},
  actions: {
    showToast(type, message) {
      if (this.toastTimeout) clearTimeout(this.toastTimeout);

      this.toastVisible = true;
      this.toastMessage = message;
      this.toastType = type;

      this.toastTimeout = setTimeout(() => {
        this.toastVisible = false;
      }, 5000);
    },
    hideToast() {
      this.toastVisible = false;
    },
  },
});
