<template>
  <div id="formPage">
    <section>
      <div class="form-container">
        <h3 class="form-heading"><span>RESET PASSWORD</span></h3>

        <div class="form-box">
          <form
            class="form"
            id="signUpEmailForm"
            ref="signUpEmailForm"
            @submit.prevent="resetPassword()"
          >
            <div class="form-row">
              <input type="text" placeholder="Email" v-model="email" />
            </div>
            <button type="submit">Continue</button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { sendPasswordResetEmail } from "@firebase/auth";
import { auth } from "../../firebase/init.js";
import { onMounted, onUnmounted, ref } from "vue";
import { useOverlayStore } from "@/stores/Overlay";
import { useRouter } from "vue-router";

const overlayStore = useOverlayStore();

const toastStore = useToastStore();

const email = ref("");

const router = useRouter();

import { useToastStore } from "@/stores/Toast";
onMounted(function () {
  document.querySelector("body").classList.add("formBody");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("formBody");
});

const resetPassword = async () => {
  if (!email.value) {
    toastStore.showToast("error", "Please enter your email address");

    return;
  }

  overlayStore.showLoadingOverlay();

  try {
    await sendPasswordResetEmail(auth, email.value);
    toastStore.showToast("success", "Reset link sent to your email address");
    setTimeout(() => {
      router.push("/login");
    }, 1000);
  } catch (err) {
    toastStore.showToast("error", "Something went wrong");
  }
  overlayStore.hideLoadingOverlay();
};
</script>
