<template>
  <div
    class="select-col"
    @mouseover="showUnselect = true"
    @mouseleave="showUnselect = false"
    ref="selectColRef"
    @contextmenu.prevent="disableRightClick"
  >
    <router-link :to="`/selects/${select.id}`" class="select-box">
      <div class="select-image">
        <img :src="select.flareImage || select.sceneImageUrl" alt="" />
      </div>
      <div class="select-details-box">
        <div class="select-details">
          <span class="select-lens-name">{{ select.name }}</span>
          <span class="select-focal-length">{{ select.focalLength }}</span>
          <span class="select-transmission">{{ select.transmission }}</span>
          <img :src="select.wishlist.icon" alt="" />
        </div>
      </div>
    </router-link>
    <div
      class="unselect-box"
      @click="handleUnsave(select.id)"
      v-if="screenStore.isMobile || showUnselect"
    >
      <i class="fas fa-spinner fa-spin" v-if="loading"></i>
      <div v-else class="unselect-box-flex">
        <img src="../../assets/img/delete.svg" alt="" />
        Unsave
      </div>
    </div>
    <img
      src="@/assets/img/dots.svg"
      alt=""
      class="select-dots"
      @click="
        () => {
          calcExpandToTop();
        }
      "
      ref="actionRef"
    />
    <Transition name="expand">
      <div
        class="select-col-actions"
        v-if="showActions"
        :class="{ 'expand-top': expandToTop }"
        ref="showActionRef"
      >
        <div class="select-col-action" @click="exportToPDF">Save PDF</div>
        <div
          class="select-col-action"
          v-if="!isFolderSelect && folders.length > 0"
        >
          Add to folder
          <div class="add-folder-box">
            <form @submit="handleFoldersSubmit">
              <div
                v-for="folder in folders"
                :key="folder.id"
                class="custom-folder-checkbox"
              >
                <input
                  :id="folder.id"
                  :value="folder.id"
                  v-model="selectedFolders"
                  type="checkbox"
                />
                <label :for="folder.id">
                  <div class="check-box"></div>
                  {{ folder.name }}
                </label>
              </div>

              <button
                :disabled="isButtonDisabled"
                type="submit"
                class="save-btn"
              >
                Save
              </button>
            </form>
          </div>
        </div>
        <div
          v-else-if="isFolderSelect"
          class="select-col-action"
          @click="handleWishlistRemove(folder.id, select.id)"
        >
          Remove from folder
        </div>
        <div class="select-col-action" v-else>
          <CustomTooltip text="Create folder to add selects"
            >Add to folder</CustomTooltip
          >
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import client from "@/api/client";
import { useScreenStore } from "@/stores/Screen";
import { useWishlistStore } from "@/stores/Wishlist";
import {
  defineProps,
  defineEmits,
  ref,
  onMounted,
  onUnmounted,
  computed,
} from "vue";
import CustomTooltip from "../CustomTooltip.vue";
import {
  addWishlistToFolders,
  removeSelectFromFolder,
} from "@/api/wishlist-folder";

const wishlistStore = useWishlistStore();

const screenStore = useScreenStore();

const props = defineProps([
  "select",
  "selectIndex",
  "folders",
  "isFolderSelect",
  "folder",
]);

const emit = defineEmits(["removeSelect", "renderPdf", "refreshFolderData"]);

const loading = ref(false);
const showUnselect = ref(false);
const actionRef = ref();
const selectedFolders = ref([]);
const selectColRef = ref(null);

const expandToTop = ref(false);

const handleUnsave = async (id) => {
  loading.value = true;
  try {
    const response = await client.delete(
      `${process.env.VUE_APP_BASE_URL}/wishlist/delete/${id}`
    );

    if (response.status !== 200) {
      loading.value = false;
      return;
    }

    wishlistStore.removeFromWishlist(id);

    emit("removeSelect", props.selectIndex);
  } catch (err) {
    console.log(err);
  }

  loading.value = false;
};

const showActions = ref(false);

const exportToPDF = () => {
  emit("renderPdf", props.select.id);
  showActions.value = false;
};

const calcExpandToTop = () => {
  showActions.value = !showActions.value;
  const dropdown = selectColRef.value;

  if (!dropdown) return;
  const bounding = dropdown.getBoundingClientRect();
  const spaceBelow = window.innerHeight - bounding.bottom;
  const spaceAbove = bounding.top;

  expandToTop.value =
    spaceBelow < (window.innerWidth < 1024 ? 200 : 100) &&
    spaceAbove > spaceBelow;
};

const handleActionDropdown = (e) => {
  if (
    e.target.closest(".select-col-actions") ||
    actionRef.value.contains(e.target)
  ) {
    return;
  }

  if (showActions.value) {
    showActions.value = false;
  }
};

const isButtonDisabled = computed(() => selectedFolders.value.length === 0);

const handleFoldersSubmit = async (e) => {
  e.preventDefault();
  const data = await addWishlistToFolders(
    selectedFolders.value,
    props.select.id
  );
  if (data) emit("refreshFolderData");

  showActions.value = false;
};

const handleWishlistRemove = async (folderId, wishlistId) => {
  const response = await removeSelectFromFolder(folderId, wishlistId);

  if (response.error) {
    return console.log("Failed to remove Folder");
  }

  emit("refreshFolderData");
};

onMounted(() => {
  window.addEventListener("click", handleActionDropdown);
});
onUnmounted(() => {
  window.removeEventListener("click", handleActionDropdown);
});
</script>

<style scoped>
.select-image {
  border: 1px solid #145aaa;
  margin-bottom: 5px;
  overflow: hidden;
}
.select-image img {
  display: block;
  transition: all 0.3s;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.select-details-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.8;
  letter-spacing: 1px;
}
.select-box {
  cursor: pointer;
  text-decoration: none;
}

.select-box:hover .select-image img {
  transform: scale(1.1);
}
.select-lens-name {
  text-transform: uppercase;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.select-details {
  display: flex;
  gap: 10px;
  align-items: center;
}
.select-details img {
  height: 40px;
}
.select-dots {
  width: 18px;
  opacity: 0.75;
  cursor: pointer;
  position: absolute;
  top: calc(100% - 30px);
  right: 0;
}

.select-col {
  flex-basis: calc(
    (100% - 80px) / 3
  ); /* for gap of 40px * 2 elements, and 3 elements per row */
  position: relative;
}

.select-col-actions {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #145aaa;
  text-transform: uppercase;
  font-size: 10px;
  transform-origin: top;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  z-index: 3;
  background: #1a1e1e;

  display: flex;
  flex-direction: column;
}
.select-col-actions.expand-top {
  top: unset;
  bottom: 15%;

  transform-origin: bottom;
}

.select-col-actions .select-col-action {
  font-size: 10px;
  padding: 7px 10px;
}
.select-col-actions .select-col-action:not(:last-child) {
  border-bottom: 1px solid #145aaa;
}

.unselect-box {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.603);

  padding: 7px;
  background-color: #04152a;
  font-size: 14px;
  cursor: pointer;
  z-index: 2;
}
.unselect-box img {
  width: 12px;
  opacity: 0.6;
}

.unselect-box-flex {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.add-folder-box {
  max-height: 150px;
  overflow-y: auto;

  -ms-overflow-style: 5px; /* IE and Edge */
  scrollbar-width: 5px;
}

.add-folder-box::-webkit-scrollbar {
  display: block !important;
  width: 2px;
  background: gray;
}

/* Track */
.add-folder-box::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
.add-folder-box::-webkit-scrollbar-thumb {
  background: #f1f1f1;
}

.custom-folder-checkbox {
  margin-block: 10px;
}
.custom-folder-checkbox input {
  display: none;
}
.custom-folder-checkbox label {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
  padding-right: 20px;
}
.custom-folder-checkbox label .check-box {
  width: 10px;
  aspect-ratio: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}
.custom-folder-checkbox label .check-box::after {
  content: "";
  position: absolute;
  top: 47%;
  left: 50%;
  font-size: 7px;

  transform: translate(-50%, -50%);
}
.custom-folder-checkbox input:checked ~ label .check-box::after {
  content: "\2713";
}

.folder-disclaimer {
  font-size: 12px;
  color: gray;
  text-transform: none;
  margin-top: 2px;
}

@media screen and (max-width: 1200px) {
  .select-col {
    flex-basis: calc((100% - 80px) / 2);
  }
}

@media screen and (max-width: 600px) {
  .select-col {
    flex-basis: 100%;
  }

  .select-col-actions .select-col-action,
  .custom-folder-checkbox label {
    font-size: 12px;
  }

  .custom-folder-checkbox label .check-box {
    width: 12px;
  }
  .custom-folder-checkbox label .check-box::after {
    font-size: 8px;
  }
}
</style>
