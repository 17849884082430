<template>
  <div class="dash-tab">
    <h3 data-v-5cba5ff2="" class="secondary-title account-title">
      Change Password
    </h3>
    <VeeForm
      class="password-change-form"
      :validation-schema="schema"
      @submit="changePassword"
    >
      <div class="form-row">
        <div class="form-col account-form-col">
          <label for="password">Old Password</label
          ><VeeField type="password" id="old_password" name="oldPassword" />
          <ErrorMessage class="form-error" name="oldPassword" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-col account-form-col">
          <label for="password">New Password</label
          ><VeeField type="password" id="password" name="newPassword" />
          <ErrorMessage class="form-error" name="newPassword" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-col account-form-col">
          <label for="password">Confirm New Password</label
          ><VeeField type="password" id="password" name="confirm_password" />
          <ErrorMessage class="form-error" name="confirm_password" />
        </div>
      </div>
      <div class="form-button-row">
        <button type="submit" class="account-submit account-button">
          Update
        </button>
        <button
          type="button"
          class="account-button account-cancel"
          @click="handleCancel"
        >
          Cancel
        </button>
      </div>
    </VeeForm>
  </div>
</template>

<script setup>
import { auth } from "@/firebase/init";
import { useOverlayStore } from "@/stores/Overlay";
import { useToastStore } from "@/stores/Toast";
import { useUsersStore } from "@/stores/Users";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "@firebase/auth";
import { useRouter } from "vue-router";

const toastStore = useToastStore();
const router = useRouter();
const overlayStore = useOverlayStore();
const userStore = useUsersStore();

const schema = {
  newPassword: "required|min:8|oneUpperCase|oneLowerCase|oneSpecialCharacter",
  confirm_password: "password_mismatch:@newPassword",
  oldPassword: "required",
};

const changePassword = async (values) => {
  overlayStore.showLoadingOverlay();

  const { newPassword, oldPassword } = values;

  const user = auth.currentUser;

  const credentials = EmailAuthProvider.credential(user.email, oldPassword);

  try {
    await reauthenticateWithCredential(user, credentials);
  } catch (err) {
    console.log(err);
    toastStore.showToast("error", "Failed to verify old password!");
    overlayStore.hideLoadingOverlay();

    return;
  }

  try {
    await updatePassword(user, newPassword);
    toastStore.showToast("success", "Password changed successfully!");
    await userStore.logOut();
    router.push("/login");
  } catch (err) {
    console.log(err);
    toastStore.showToast(
      "error",
      "Something went wrong. Please try again later!"
    );
  }

  overlayStore.hideLoadingOverlay();
};

const handleCancel = () => {
  router.push("/");
};
</script>

<style scoped>
.form-col {
  max-width: 300px;
}

.form-error {
  margin-left: 0;
}
</style>
