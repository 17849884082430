/* eslint-disable */
<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} cineflares. All rights reserved.
        Test conceptualized and performed by
        <span class="underline"
          ><a class="url" href="https://markusforderer.com" target="_blank"
            >Markus Forderer, ASC</a
          ></span
        >
      </div>
      <div class="footer-options">
        <router-link to="/termsofuse" class="footer-link"
          >Terms of use</router-link
        >
        <router-link to="/policy" class="footer-link">Privacy</router-link>
        <a class="url" href="mailto:contact@cineflares.com">Contact</a>
      </div>

      <a
        href="http://arbyte.solutions/"
        target="_blank"
        class="footer-powered url"
        >Developed by <img src="../assets/img/arbyte-logo-final.svg" alt=""
      /></a>
    </div>
  </footer>
</template>
