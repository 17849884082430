<template>
  <Body v-if="!screenStore.isMobile" />
  <MobileMainSection v-else></MobileMainSection>
</template>

<script setup>
import Body from "@/components/Body.vue";
import { useScreenStore } from "@/stores/Screen";
import MobileMainSection from "@/components/MobileComponents/MobileMainSection.vue";
const screenStore = useScreenStore();
</script>

<script>
export default {
  components: {
    Body,
  },
};
</script>
