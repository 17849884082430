import { createRouter, createWebHistory } from "vue-router";
import MainView from "../views/MainView";
import NewsView from "../views/NewsView";
import AboutView from "../views/AboutView";
import SceneView from "../views/SceneView";
import TermsOfUseView from "../views/TermsofuseView";
import PolicyView from "../views/PolicyView";
import PricingView from "../views/PricingView.vue";
import SignUp from "../views/SignupView.vue";
import Login from "../views/LoginView.vue";
import EnterEmail from "../views/ResetPassword/EnterEmailView.vue";

import SelectsView from "../views/SelectsView.vue";
import SelectDetailView from "@/views/SelectDetailView.vue";
import { useUsersStore } from "@/stores/Users";
import AccountView from "@/views/AccountView.vue";
import ProfileTab from "@/components/account/ProfileTab.vue";
import PaymentTab from "@/components/account/PaymentTab.vue";
import PasswordTab from "@/components/account/PasswordTab.vue";
import PaymentSuccessView from "@/views/PaymentSuccessView.vue";
import BrowseView from "@/views/BrowseView.vue";
import SelectFolderView from "@/views/SelectFolderView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "flares",
      component: MainView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/selects",
      name: "selects",
      component: SelectsView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/selects/:id",
      name: "selectsDetails",
      component: SelectDetailView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/selects/folder/:id",
      name: "selectFolder",
      component: SelectFolderView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/signup",
      component: SignUp,
      meta: {
        restrictWhenLogged: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        restrictWhenLogged: true,
      },
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: EnterEmail,
      meta: {
        restrictWhenLogged: true,
      },
    },
    {
      path: "/news",
      component: NewsView,
    },
    {
      path: "/about",
      component: AboutView,
    },
    {
      path: "/scene",
      component: SceneView,
      meta: {
        requiresAuth: true,
        requiresPremium: true,
      },
    },
    {
      path: "/termsofuse",
      component: TermsOfUseView,
    },
    {
      path: "/policy",
      component: PolicyView,
    },
    {
      path: "/pricing",
      component: PricingView,
    },
    {
      path: "/browse",
      component: BrowseView,
      meta: {
        requiresAuth: true,
        requiresPremium: true,
      },
    },
    {
      path: "/success",
      component: PaymentSuccessView,
    },
    {
      path: "/account",
      component: AccountView,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          redirect: { name: "profile" },
        },
        {
          path: "profile",
          name: "profile",
          component: ProfileTab,
        },
        {
          path: "payment",
          name: "payment",
          component: PaymentTab,
        },
        {
          path: "password",
          name: "password",
          component: PasswordTab,
        },
      ],
    },
    {
      path: "/:catchAll(.*)*",
      redirect: { name: "flares" },
    },
  ],
  duplicateNavigationPolicy: "reload",
});

router.beforeEach(async (to, from, next) => {
  const store = useUsersStore();

  if (store.isLoggedInValue && !store.profileLoaded) {
    await store.getProfile();
  }

  if (to.meta.restrictWhenLogged) {
    if (!store.isLoggedInValue) {
      next();
      return;
    } else {
      next({ name: "flares" });
    }
  }

  if (!to.meta.requiresAuth) {
    return next();
  }

  if (store.isLoggedInValue) {
    if (to.meta.requiresAdmin) {
      if (store.userRole === "ADMIN") {
        next();
      } else {
        next({ name: "flares" });
        return;
      }
    }

    if (to.meta.requiresPremium) {
      if (store.userDetails?.isPremiumUser) {
        next();
      } else {
        next({ name: "flares" });
        return;
      }
    }

    next();
  } else {
    next({ name: "login" });
  }
});
export default router;
