<template>
  <div class="selectsBody secondary-body">
    <AlertBox v-if="showSettingsBox" @closeAlert="showSettingsBox = false">
      <div class="form-row" @submit="handleFolderEditSubmit">
        <form class="form-col account-form-col">
          <label for="password">Folder Name</label>
          <div class="input-row">
            <input v-model="folderName" type="text" name="name" />
            <button
              class="save-btn"
              type="submit"
              :disabled="folderName === folder.name"
              :class="{ disabled: folderName === folder.name }"
            >
              Save
            </button>
          </div>
          <p class="form-error"></p>
        </form>
      </div>
      <button class="del-btn" @click="handleFolderDelete">Delete Folder</button>
    </AlertBox>
    <div class="container newsbody-container logo-container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <section id="selects-section">
      <div class="container">
        <div class="column left"></div>
        <div class="column middle">
          <div class="title-row">
            <h3 class="secondary-title">
              <router-link to="/selects">
                <img
                  src="../assets/img/back-icon.svg"
                  class="back-btn"
                  alt=""
                />
              </router-link>
              {{ folder?.name }}
            </h3>
            <div>
              <img
                class="action-button"
                src="@/assets/img/settings.svg"
                @click="showSettingsBox = true"
                alt=""
              />
            </div>
          </div>

          <div class="select-row" v-if="savedVideos?.length > 0">
            <SelectCard
              v-for="(select, index) in savedVideos"
              :key="select.id"
              :select="select"
              :selectIndex="index"
              @removeSelect="handleSelectRemove"
              @renderPdf="renderPdfContents"
              @refreshFolderData="fetchFolderDetails"
              :isFolderSelect="true"
              :folder="folder"
            >
            </SelectCard>
          </div>
          <div v-else class="info-text">Folder is empty!</div>
        </div>

        <div class="column right"></div>
      </div>
    </section>

    <div v-if="selectPdfDetails" style="display: none">
      <div ref="pdfRef">
        <SelectPDF :select="selectPdfDetails" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import SelectCard from "@/components/Selects/SelectCard.vue";
import SelectPDF from "@/components/Selects/SelectPDF.vue";
import savePdf from "@/includes/savePdf";
import { useRoute, useRouter } from "vue-router";
import { useOverlayStore } from "@/stores/Overlay";
import { useUsersStore } from "@/stores/Users";
import client from "@/api/client";
import {
  deleteFolder,
  getFolderDetails,
  updateFolder,
} from "@/api/wishlist-folder";
import AlertBox from "@/components/AlertBox.vue";
const router = useRouter();
const route = useRoute();

const overlayStore = useOverlayStore();

const savedVideos = ref([]);
const folder = ref();
const selectPdfDetails = ref(null);
const pdfRef = ref(null);
const showSettingsBox = ref(false);
const folderName = ref(null);

const userStore = useUsersStore();

const handleSelectRemove = (selectIndex) => {
  savedVideos.value.splice(selectIndex, 1);
};

const convertToBase64 = async (url) => {
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}/image/base64?url=${url}`
    );

    if (response.data.code === "auth/id-token-expired") {
      router.push("/login");
      return;
    }
    return response.data.base64;
  } catch (err) {
    console.log(err);
  }
};

const fetchSelectDetails = async (id) => {
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}/wishlist/id/${id}`
    );

    const flareImageBase64 = await convertToBase64(
      response.data.sceneImageUrl || response.data.flareImage
    );
    const iconBase64 = await convertToBase64(response.data.wishlist.icon);

    selectPdfDetails.value = response.data;
    selectPdfDetails.value.flareImageBase64 = flareImageBase64;
    selectPdfDetails.value.iconBase64 = iconBase64;
  } catch (err) {
    console.log(err);
  }
};

const renderPdfContents = async (id) => {
  overlayStore.showLoadingOverlay();
  await fetchSelectDetails(id);
  await savePdf(pdfRef.value, selectPdfDetails);

  overlayStore.hideLoadingOverlay();
};

const fetchFolderDetails = async () => {
  const data = await getFolderDetails(route.params.id);

  if (!data || data.error) {
    return router.push("/");
  }

  folder.value = data;
  savedVideos.value = folder.value?.wishlists;
  folderName.value = folder.value?.name;
};

const handleFolderDelete = async () => {
  const response = await deleteFolder(folder.value.id);
  if (response.error) {
    return console.log("Failed to Delete Folder!");
  }

  router.push("/selects");
};

const handleFolderEditSubmit = async (e) => {
  e.preventDefault();

  if (!folderName.value) return;

  const response = await updateFolder(folder.value.id, folderName.value);

  if (response.error) {
    return console.log("Failed to update Folder!");
  }

  await fetchFolderDetails();

  showSettingsBox.value = false;
};

onMounted(async () => {
  await fetchFolderDetails();
});
</script>

<style scoped>
.middle {
  flex: 5;
  display: block;
}

.select-row {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.secondary-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-btn {
  width: 17px;
  opacity: 0.8;
  cursor: pointer;
  display: block;
}

.account-form-col {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.form-row {
  gap: 0;
}

@media screen and (max-width: 1024px) {
  .logo-container {
    display: none;
  }

  .secondary-body {
    padding-top: 0;
    min-height: 70vh;
  }
}
</style>
