import client from "./client";

export const getAboutDetails = async () => {
  try {
    const { data } = await client.get(`/about`);

    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return { error: response.data };

    return { error: err.message || err };
  }
};
