<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div class="body">
    <AlertBox v-if="showPremiumAlert" @closeAlert="showPremiumAlert = false">
      <div class="premium-upgrade-box">
        <img src="../assets/img/premium-icon.svg" alt="" />
        <h4 class="gradient-text">Pro feature only</h4>
        <p>
          Upgrade to PRO account to unlock more features and access to our full
          PRO library.
        </p>
        <RouterLink class="pricing-link" to="/pricing">View Plans</RouterLink>
      </div>
    </AlertBox>
    <div class="container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <div class="container">
      <div class="column left">
        <SearchComponent
          @focus-event="searchFocus"
          @blur-event="searchBlur"
          :isLeftSearch="true"
        />
        <ScrollableList side="left" v-if="store.datafetched === true" />
      </div>

      <MiddleContainer
        v-if="store.datafetched === true"
        @updateLeftSingleDetails="updateLeftSingleDetails"
        @updateRightSingleDetails="updateRightSingleDetails"
      />

      <div class="column right">
        <SearchComponent
          @focus-event="searchFocus"
          @blur-event="searchBlur"
          :isLeftSearch="false"
        />
        <ScrollableList side="right" v-if="store.datafetched === true" />
      </div>
    </div>
    <div class="detailsContainer">
      <div class="column left"></div>
      <div class="column middle details-middle">
        <DetailsContainer
          v-if="leftSingleVideoDetail"
          side="left"
          :videoDetails="leftSingleVideoDetail"
          :showLensDetails="showLensDetails"
          @showDetails="handleDetailsShow"
        />
        <DetailsContainer
          side="right"
          v-if="rightSingleVideoDetail"
          :videoDetails="rightSingleVideoDetail"
          @showDetails="handleDetailsShow"
          :showLensDetails="showLensDetails"
        />
      </div>
      <div class="column right"></div>
    </div>
    <div class="btn-group" id="btn-group">
      <button
        id="fullscreen-button"
        class="btn btn-warning"
        disabled
        style="display: none"
      >
        Toggle Fullscreen
      </button>
    </div>
  </div>
</template>

<script setup>
import MiddleContainer from "./MiddleContainer.vue";
import ScrollableList from "./ScrollableList.vue";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useLensesStore } from "../stores/Lenses";
import SearchComponent from "./SearchComponent.vue";
import DetailsContainer from "./DetailsContainer.vue";
import "../styles/Body.css";
import AlertBox from "./AlertBox.vue";
import { useUsersStore } from "@/stores/Users";
const store = useLensesStore();
const userStore = useUsersStore();

const searchFocused = ref(false);
const showLensDetails = ref(false);
const showPremiumAlert = ref(false);

const leftSingleVideoDetail = ref(null);
const rightSingleVideoDetail = ref(null);

const updateLeftSingleDetails = (data) => {
  leftSingleVideoDetail.value = data;
};
const updateRightSingleDetails = (data) => {
  rightSingleVideoDetail.value = data;
};

const handleDetailsShow = () => {
  if (store.isPremiumUser) {
    showLensDetails.value = !showLensDetails.value;
  } else {
    showPremiumAlert.value = true;
  }
};

watch(rightSingleVideoDetail, () => {
  const lensCategoryName = store.data.find(
    (lens) => lens.id === rightSingleVideoDetail.value?.lensCategoryId
  )?.name;
  if (!lensCategoryName) return;

  rightSingleVideoDetail.value.lensCategoryName = lensCategoryName;
});

watch(leftSingleVideoDetail, () => {
  const lensCategoryName = store.data.find(
    (lens) => lens.id === leftSingleVideoDetail.value?.lensCategoryId
  )?.name;
  if (!lensCategoryName) return;
  leftSingleVideoDetail.value.lensCategoryName = lensCategoryName;
});

onMounted(async () => {
  await store.fetchData();
  window.addEventListener("keydown", handleKeyEvents);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyEvents);
});

const searchFocus = () => {
  searchFocused.value = true;
};

const searchBlur = () => {
  searchFocused.value = false;
};

function handleKeyEvents(e) {
  if (searchFocused.value) return;

  e.preventDefault();

  const video1 = document.getElementById("video1");
  const video2 = document.getElementById("video2");
  const playBackSpeed = 1;

  if (e.key === " ") {
    if (store.pauseVideo) {
      video1.play();
      video2.play();
    } else if (store.playVideo) {
      video1.pause();
      video2.pause();
    }
  } else if (e.key === "ArrowRight") {
    if (video1.currentTime > 0 && video2.currentTime > 0) {
      video1.currentTime += playBackSpeed;
      video2.currentTime += playBackSpeed;
    }
  } else if (e.key === "ArrowLeft") {
    if (video1.currentTime > 0 && video2.currentTime > 0) {
      // Rewind when the left arrow key is pressed4
      video1.currentTime -= playBackSpeed;
      video2.currentTime -= playBackSpeed;
    }
  }
}
</script>
<style scoped>
.detailsContainer {
  margin-top: 80px;
  display: flex;
  color: white;
}

.mainContainer {
  overflow: scroll;
}

.details-middle {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
</style>
