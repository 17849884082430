<template>
  <div id="formPage">
    <section>
      <div class="form-container">
        <h3 class="form-heading"><span>SIGN UP</span> for free</h3>

        <div class="form-box">
          <VeeForm class="form" :validation-schema="schema" @submit="register">
            <div class="form-row">
              <div class="form-col">
                <VeeField
                  type="text"
                  placeholder="Email"
                  name="email"
                  v-model="hashEmail"
                />
                <ErrorMessage class="form-error" name="email" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <VeeField
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                />
                <ErrorMessage class="form-error" name="firstName" />
              </div>
              <div class="form-col">
                <VeeField type="text" placeholder="Last Name" name="lastName" />
                <ErrorMessage class="form-error" name="lastName" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <VeeField
                  type="password"
                  placeholder="Password"
                  name="password"
                />
                <ErrorMessage class="form-error" name="password" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <VeeField
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                />
                <ErrorMessage class="form-error" name="confirm_password" />
              </div>
            </div>
            <div class="disclaimer-text">
              Password must be atleast 8 characters long with one uppercase
              letter, one lower case letter, and one special character.
            </div>
            <div class="form-row">
              <div class="form-col">
                <VeeField as="select" name="industry">
                  <option value="" disabled selected>
                    Select your industry
                  </option>
                  <option value="cinematographer">Cinematographer</option>
                  <option value="director">Director</option>
                  <option value="vfxArtist">VFX Artist</option>
                  <option value="vfxVendor">VFX Vendor</option>
                  <option value="cameraRentalHouse">Camera Rental House</option>
                  <option value="lensManufacturer">Lens Manufacturer</option>
                  <option value="press">Press</option>
                  <option value="filmStudent">Film Student</option>
                  <option value="other">Other</option>
                </VeeField>
                <ErrorMessage class="form-error" name="industry" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <VeeField type="text" placeholder="Company" name="company" />
                <ErrorMessage class="form-error" name="company" />
              </div>
            </div>
            <div class="form-row custom-checkbox">
              <div class="form-col">
                <ErrorMessage class="form-error tos-error" name="newsletter" />

                <VeeField
                  type="checkbox"
                  id="newsletter"
                  name="newsletter"
                  value="1"
                />
                <label for="newsletter">
                  <div class="pseudo-checkbox"></div>
                  <span> Get emails when we add new lenses and features </span>
                </label>
              </div>
            </div>
            <div class="form-row custom-checkbox">
              <div class="form-col">
                <VeeField
                  type="checkbox"
                  id="acceptTerms"
                  value="1"
                  name="tos"
                />
                <label for="acceptTerms">
                  <div class="pseudo-checkbox"></div>
                  <span>
                    By checking this box you agree to our
                    <RouterLink to="/termsofuse">Terms of Use</RouterLink> and
                    <RouterLink to="/policy">Privacy Policy</RouterLink>
                  </span>
                </label>
                <ErrorMessage class="form-error tos-error" name="tos" />
              </div>
            </div>

            <div class="captcha-box">
              <vue-recaptcha
                ref="recaptcha"
                :sitekey="appKey"
                @verify="validCaptcha"
                @expired="invalidCaptcha"
                @error="invalidCaptcha"
              />
              <p class="form-error" v-if="showCaptchaError">
                Please verify you are a human.
              </p>
            </div>

            <button type="submit">Welcome to CINEFLARES</button>
          </VeeForm>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUsersStore } from "@/stores/Users";
import { useOverlayStore } from "@/stores/Overlay";
import { VueRecaptcha } from "vue-recaptcha";
import { useToastStore } from "@/stores/Toast";
import client from "@/api/client";
const overlayStore = useOverlayStore();
const toastStore = useToastStore();

const route = useRoute();
const router = useRouter();
const store = useUsersStore();

const isHuman = ref(false);
const showCaptchaError = ref(false);

const schema = {
  firstName: "required|min:3|max:100|alpha_spaces",
  lastName: "required|min:3|max:100|alpha_spaces",
  email: "required|min:3|max:100|email",
  password: "required|min:8|oneUpperCase|oneLowerCase|oneSpecialCharacter",
  confirm_password: "password_mismatch:@password",
  industry: "required",
  company: "required",
  tos: "tos",
};

const hashEmail = ref("");
const appKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

const validCaptcha = () => {
  isHuman.value = true;
  showCaptchaError.value = false;
};
const invalidCaptcha = () => {
  isHuman.value = false;
  showCaptchaError.value = true;
};

const register = async (values) => {
  if (!isHuman.value) {
    showCaptchaError.value = true;
    return;
  }
  overlayStore.showLoadingOverlay();

  const payload = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    industry: values.industry,
    company: values.company,
    hasSubscribedForNewsletter: values.newsletter ? true : false,
  };

  try {
    const response = await store.signup(payload);
    if (response.error) {
      toastStore.showToast();
      toastStore.type = "error";
      toastStore.toastMessage = response.message;

      overlayStore.hideLoadingOverlay();

      return;
    }
  } catch (err) {
    console.log(err);
    toastStore.showToast(
      "error",
      "Something went wrong! Please try again later."
    );

    overlayStore.hideLoadingOverlay();
    return;
  }

  toastStore.showToast(
    "success",
    "Account Created Successfully! Please Log In."
  );

  overlayStore.hideLoadingOverlay();
  router.push("/");
};

onMounted(async function () {
  document.querySelector("body").classList.add("formBody");

  const hashQuery = route.query.hash;

  if (hashQuery) {
    try {
      const response = await client.get(
        `${process.env.VUE_APP_BASE_URL}/subscription/hash?hash=${hashQuery}`
      );

      if (response.data.code === "auth/id-token-expired") {
        router.push("/login");
        return;
      }

      if (response.status === 200) {
        hashEmail.value = response.data.email;
      }
    } catch (err) {
      console.log(err);
    }
  }
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("formBody");
});
</script>

<style scoped></style>
