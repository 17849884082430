<template>
  <div class="folder-box">
    <img src="@/assets/img/folder-icon.svg" class="folder-bg-img" alt="" />
    <div class="folder-contents" v-if="type === 'create'">
      <CreateFolder @refresh-folder-data="emit('refreshFolderData')" />
    </div>
    <router-link
      :to="`/selects/folder/${folder?.id}`"
      class="folder-contents"
      v-else
    >
      <div class="empty-img-box" v-if="folder.wishlists.length === 0">
        <img class="empty-icon" src="../../assets/img/empty.svg" alt="" />
      </div>
      <div v-else class="folder-thumb-box">
        <div
          class="folder-thumb-img"
          :class="{ 'flex-2': folder.wishlists.length > 2 }"
        >
          <img
            :src="
              folder.wishlists[0].flareImage ||
              folder.wishlists[0].sceneImageUrl
            "
            alt=""
            class="folder-thumbnail"
          />
        </div>
        <div class="folder-thumb-img" v-if="folder.wishlists.length > 1">
          <img
            :src="
              folder.wishlists[1].flareImage ||
              folder.wishlists[1].sceneImageUrl
            "
            alt=""
            class="folder-thumbnail"
          />
          <div v-if="folder.wishlists.length > 2" class="additional-indicator">
            +{{ folder.wishlists.length - 2 }}
          </div>
        </div>
      </div>
      <div class="folder-details">
        <h4 class="folder-name">{{ folder.name }}</h4>
        <span
          >{{ folder.wishlists.length }}
          {{ folder.wishlists.length === 1 ? "item" : "items" }}</span
        >
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import CreateFolder from "./CreateFolder.vue";
defineProps(["type", "folder"]);
const emit = defineEmits(["refreshFolderData"]);
</script>

<style scoped>
.folder-box {
  position: relative;
  width: 220px;
  aspect-ratio: 4/3;
  cursor: pointer;
}

.flex-2 {
  flex-grow: 2 !important;
}

.folder-bg-img {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.folder-contents {
  height: 100%;
  padding-top: 9%;
  padding-bottom: 5px;
  padding-inline: 4%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.folder-thumb-box {
  height: 70%;
  display: flex;
  gap: 4%;
}

.folder-thumb-img {
  flex: 1;
  display: flex;
  gap: 8%;
  flex-direction: column;
}

.highlight .folder-bg-img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(15%) saturate(2462%)
    hue-rotate(183deg) brightness(88%) contrast(99%);
}

.folder-thumb-img > * {
  flex-basis: 50%;
}
.folder-thumbnail {
  border-radius: 5px;
  flex: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.folder-details {
  margin-top: auto;
}

.folder-details .folder-name {
  margin-top: 5px;
  margin-bottom: 0;
  color: white;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.2px;
  opacity: 0.8;
}

.folder-details span {
  color: white;
  opacity: 0.5;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.additional-indicator {
  background: #1e2d44;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.65);
}

.empty-icon {
  width: 4rem;
  margin: auto;
  opacity: 0.3;
}

.empty-img-box {
  margin-top: auto;
  text-align: center;
}
</style>
