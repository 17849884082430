<template>
  <h3 class="secondary-title"><span>Sample Media / </span> {{ lensName }}</h3>
  <div class="sample-image-row">
    <div
      class="sample-img-col"
      v-for="(src, index) in images"
      :key="index"
      @click="() => showMedia(src)"
    >
      <div class="media-wrapper" oncontextmenu="return false;">
        <template v-if="isImage(src)">
          <img :src="src" class="media" />
        </template>
        <template v-else>
          <div class="video-thumb">
            <img :src="videoThumbnails[index]" class="media" />
            <!-- <div class="play-button">▶</div> -->
            <img
              src="../assets/img/play.png"
              id="play-button"
              @click="hideVideo"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- Toolbar has been removed with display none property on : vel-toolbar-->
  <vue-easy-lightbox
    v-if="lightboxVisible"
    :visible="lightboxVisible"
    :imgs="images.filter(isImage)"
    :index="lightboxIndex"
    @hide="onHideLightbox"
    moveDisabled="true"
    rotateDisabled="true"
    zoomDisabled="true"
    @contextmenu.prevent="disableRightClick"
    pinchDisabled="true"
  ></vue-easy-lightbox>
  <div v-if="videoVisible" class="video-overlay">
    <img
      src="../assets/img/cross-icon.svg"
      class="close-button"
      @click="hideVideo"
    />
    <video
      controls
      autoplay
      disablepictureinpicture
      oncontextmenu="return false;"
      controlslist="nodownload noremoteplayback noplaybackrate"
    >
      <source :src="currentVideoSrc" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script setup>
import { disableRightClick } from "@/includes/helper";
import { ref, defineProps, onMounted } from "vue";

const props = defineProps(["images", "lensName"]);

const lightboxVisible = ref(false);
const videoVisible = ref(false);
const lightboxIndex = ref(0);
const currentVideoSrc = ref("");
const videoThumbnails = ref([]);

const isImage = (src) => {
  return !src.includes(".mp4");
};

const onHideLightbox = () => (lightboxVisible.value = false);
const hideVideo = () => (videoVisible.value = false);

const showMedia = (src) => {
  if (isImage(src)) {
    lightboxIndex.value = props.images.filter(isImage).indexOf(src);
    lightboxVisible.value = true;
  } else {
    currentVideoSrc.value = src;
    videoVisible.value = true;
  }
};

function getVideoThumbnail(videoUrl, seekTime = 1.0) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");

    video.src = videoUrl;
    video.crossOrigin = "Anonymous";
    video.load();
    video.addEventListener("loadeddata", () => {
      // Ensure the video duration is long enough
      if (video.duration < seekTime) {
        reject(new Error("Video is too short."));
        return;
      }

      // Seek to the desired time and capture the frame
      video.currentTime = seekTime;
      video.addEventListener(
        "seeked",
        () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext("2d");
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnailDataUrl = canvas.toDataURL("image/png");
          resolve(thumbnailDataUrl);
        },
        { once: true }
      );
    });

    video.addEventListener("error", (e) => {
      reject(new Error(`Failed to load video: ${e.message}`));
    });
  });
}

onMounted(async () => {
  // Prevent drag and drop download
  document.querySelectorAll("img").forEach((img) => {
    img.addEventListener("dragstart", function (e) {
      e.preventDefault();
    });
  });

  // Generate thumbnails for videos on component mount
  for (let src of props.images) {
    if (isImage(src)) {
      videoThumbnails.value.push(src); // Use the image src directly
    } else {
      try {
        const thumbnail = await getVideoThumbnail(src);
        videoThumbnails.value.push(thumbnail);
      } catch (error) {
        console.error(error);
      }
    }
  }
});
</script>

<style scoped>
.secondary-title {
  font-size: 12px;
}
.secondary-title span {
  color: rgb(120, 120, 120);
}

.sample-image-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
}

.sample-img-col {
  flex-basis: calc((100% - 20px) / 3);
  cursor: pointer;
  position: relative;
  border: 1px solid grey;
}

@media screen and (max-width: 740px) {
  .sample-img-col {
    flex-basis: 100%;
    border: 1px solid grey;
  }
}
.sample-img-col img,
.video-thumb img {
  width: 100%;
  display: block;
}

.media-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-thumb .media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.video-overlay video {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  z-index: 1001;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  scale: 1.1;
}

#play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px; /* Adjust size as needed */
  height: 48px; /* Adjust size as needed */
}
</style>
