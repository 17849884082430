<template>
  <div
    class="tooltip-wrapper"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
    @touchstart="toggleTooltip"
  >
    <slot></slot>
    <div v-if="visible" class="tooltip">
      <img src="../assets/img/premium-icon.svg" alt="" />
      <p class="gradient-text">{{ text }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, onUnmounted } from "vue";

defineProps({
  text: String,
});

const visible = ref(false);

const showTooltip = () => {
  visible.value = true;
};

const hideTooltip = () => {
  visible.value = false;
};

const toggleTooltip = (event) => {
  event.stopPropagation();
  visible.value = !visible.value;
};

const handleClickOutside = (event) => {
  if (!event.target.closest(".tooltip-wrapper")) {
    hideTooltip();
  }
};

onMounted(() => {
  document.addEventListener("touchstart", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("touchstart", handleClickOutside);
});
</script>

<style scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #292c2c;
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  border-radius: 5px;
  z-index: 1;
  white-space: nowrap;
  align-items: center;
  gap: 5px;
  width: max-content;

  display: flex;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #292c2c transparent transparent transparent;
}

.tooltip img {
  width: 1rem;
}
.tooltip p {
  font-weight: bold;
}
</style>
