import client from "./client";

export const getWishlist = async () => {
  try {
    const { data } = await client.get(`/wishlist`);
    return data;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};

export const removeFromWishlist = async (wishlistId) => {
  try {
    const response = await client.delete(`/wishlist/delete/${wishlistId}`);

    return response;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};

export const addToWishlist = async (payload) => {
  try {
    const response = await client.post(`/wishlist`, payload);

    return response;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};
