function formatDate(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const suffix = getDaySuffix(day);

  return `${day}${suffix} ${month} ${year}`;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function convertToTitleCase(str) {
  // List of common small words to be kept lowercase unless they appear at the beginning or end
  const smallWords =
    /^(a|an|and|as|at|but|by|for|if|in|nor|of|on|or|so|the|to|up|via|vs|with)$/i;

  return str
    .toLowerCase()
    .replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    })
    .replace(smallWords, function (match) {
      return match.toLowerCase();
    });
}

const disableRightClick = (e) => {
  e.preventDefault();
};

export { formatDate, convertToTitleCase, disableRightClick };
