<template>
  <div class="alert-container" @click="handleAlertClose">
    <div class="alert-box">
      <img
        src="@/assets/img/cross-icon.svg"
        class="alert-cross"
        alt=""
        @click="emits('closeAlert')"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emits = defineEmits(["closeAlert"]);

const handleAlertClose = (e) => {
  if (!e.target.classList.contains("alert-container")) return;

  emits("closeAlert");
};
</script>

<style scoped>
.alert-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  inset: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-box {
  width: 300px;
  background: #1a1e1e;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  animation: grow 0.5s ease;
}

.alert-cross {
  width: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  opacity: 0.6;
}

@keyframes grow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
