<template>
  <div class="termsbody">
    <div class="container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <div
      style="
        display: flex;
        justify-content: flex-end;
        color: white;
        padding-right: 20px;
        margin: 10px;
      "
      v-show="store.isMobile"
      @click="exitPage()"
    >
      <img src="../assets/img/close.png" />
    </div>
    <div style="display: flex">
      <div class="column left"></div>
      <section id="terms-container">
        <p class="secondary-paragraph">
          The following is a legal agreement between the USER and the owner
          TRUELENS PRODUCTIONS, INC. DBA CINEFLARES&trade; for all digital
          products, images and videos found on www.cineflares.com. By signing up
          to use CINEFLARES&trade;, you agree to be bound by the terms of this
          agreement. CINEFLARES&trade; is currently in a BETA status. Updates
          and changes without prior notice.
        </p>
        <p class="secondary-paragraph">
          Copyright All CINEFLARES&trade; products, images and videos are
          protected under US and in- ternational copyright law.
          CINEFLARES&trade; owns and reserves all rights except for those ex-
          pressly granted in this license agreement.
        </p>
        <p class="secondary-paragraph">
          License You acknowledge that by signing up on CINEFLARES.com, the
          ownership of all products, images and videos does not get transferred
          to you and you must not claim that it is yours. This license is
          non-transferable and non-exclusive. The contents may be viewed on all
          digital devices directly owned by the individual user of
          CINEFLARES&trade;. The contents may be used, for your educational and
          research purposes for personal and commercial work. You may not sell,
          distribute, sublicense, loan or incorporate the contents in separate
          products. You may not share your personal access link and account with
          people outside of your house- hold. You may not sell or distribute our
          original lens test files. You may also not sell or dis- tribute our
          files on stock footage websites.
        </p>
        <p class="secondary-paragraph">
          &copy; CINEFLARES Last update: November 9, 2023
        </p>
      </section>
      <div class="column right"></div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted } from "vue";
import "../styles/Termsofuse.css";
import { useScreenStore } from "@/stores/Screen";
import { useRouter } from "vue-router";
import { useUsersStore } from "@/stores/Users";
const router = useRouter();

const store = useScreenStore();
const userStore = useUsersStore();

onMounted(() => {
  store.checkWindowSize();
  document.querySelector("body").classList.add("backgroundImage");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("backgroundImage");
});

function exitPage() {
  router.push("/");
}
</script>
