<template>
  <div class="section-container">
    <div class="image-container">
      <img
        :src="imageUrl"
        v-if="!additionalImages || additionalImages.length === 0"
        :style="{ cursor: 'pointer' }"
        @click="() => showMedia()"
      />
      <div v-else>
        <Carousel
          :items-to-show="1"
          :wrap-around="true"
          :autoplay="3000"
          ref="myCarousel"
        >
          <Slide v-for="image in carouselImages" :key="image" @click="() => showMedia()">
            <div class="carousel__item">
              <img :src="image" alt="" class="carouselImg" />
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
    <div class="text-container">
      <div class="title">
        {{ title }}
      </div>
      <span class="subtitle" v-if="date">{{ formattedDate }} </span>
      <div v-html="description"></div>
      <table></table>
    </div>
  </div>
  <vue-easy-lightbox
    v-if="lightboxVisible"
    :visible="lightboxVisible"
    :imgs="carouselImages"
    :index="lightboxIndex"
    @hide="onHideLightbox"
    moveDisabled
    rotateDisabled
    zoomDisabled
    @contextmenu.prevent="disableRightClick"
    pinchDisabled
  ></vue-easy-lightbox>
</template>

<script setup>
import { disableRightClick } from "@/includes/helper";
import { defineProps, onMounted, ref } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

const lightboxVisible = ref(false);
const lightboxIndex = ref(0);

const props = defineProps({
  title: String,
  description: String,
  date: String,
  imageUrl: String,
  additionalImages: Array,
});

const carouselImages = ref([]);
const myCarousel = ref(null);

const showMedia = () => {
  lightboxIndex.value = myCarousel?.value?.data?.currentSlide?.value || 0;

  lightboxVisible.value = true;
};
const onHideLightbox = () => (lightboxVisible.value = false);

carouselImages.value =
  props.additionalImages && props.additionalImages?.length > 0
    ? [props.imageUrl, ...props.additionalImages]
    : [props.imageUrl];

const formattedDate = ref("");

onMounted(() => {
  //format the date
  const options = { month: "long", day: "numeric", year: "numeric" };
  formattedDate.value = new Date(props.date).toLocaleDateString(undefined, options);
});
</script>

<style scoped>
.carousel__pagination {
  padding-top: 0;
}
</style>
